<script>
import axios from "axios";

export default {
    name: "NoSeatPlanAdmin",
    props: {
        eventId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            discounts: [],
            categories: [],
            errorMsg: "",
            event: {},
            cart: [],
            first_name: "",
            last_name: "",
            address: "",
            zip_code: "",
            city: "",
            email: "",
            phone: "",
            notes: "",
            bookingType: "sell_ticket",
            proceedToCheckoutTxt: "Book",
            showForm: false,
        };
    },
    mounted() {
        this.fetchEvent(this.eventId);
    },
    computed: {
        isCheckoutDisabled() {
            // Check if any tickets have been selected
            return this.filterTickets().length === 0;
        },
    },
    watch: {
        bookingType(newVal) {
            if (newVal === "free_ticket") {
                this.cart.forEach((seat) => {
                    seat.manualPrice = 0;
                });
            }
        },
    },
    methods: {
        toggleForm() {
            this.showForm = !this.showForm;
        },
        showToastMessage() {
            if (this.isCheckoutDisabled) {
                // Replace this with your preferred method of showing a toast message
                alert("Der Warenkorb ist leer. Bitte fügen Sie Tickets hinzu.");
            }
        },
        async fetchEvent(eventId) {
            try {
                const response = await axios.post(`/api/events/${eventId}`);
                this.processEventData(response.data);
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        },
        processEventData(data) {
            this.event = data;
            this.processDiscounts(data.discounts);
            this.processCategories(data.seat_plan_categories);
        },
        processDiscounts(discounts) {
            this.discounts = discounts.map((discount) => ({
                id: discount.id,
                name: discount.name,
                type: discount.type,
                fixed: discount.fixed,
                percentage: discount.percentage,
                description: discount.description,
            }));
        },
        processCategories(categories) {
            this.categories = categories.map((category) => {
                const discountPrices = this.calculateDiscountPrices(category);

                return {
                    id: category.id,
                    name: category.name,
                    price: category.price,
                    description: category.description,
                    regularPrice: {
                        name: "Regular",
                        price: this.formatPrice(category.price),
                        count: 0,
                    },
                    discountPrices: discountPrices,
                    showMore: discountPrices.length <= 2,
                };
            });
        },
        calculateDiscountPrices(category) {
            return this.discounts.map((discount) => {
                const price =
                    discount.type === "percentage"
                        ? category.price -
                          category.price * (discount.percentage / 100)
                        : category.price - discount.fixed;

                return {
                    discount_id: discount.id,
                    name: discount.name,
                    price: this.formatPrice(price),
                    count: 0,
                };
            });
        },
        decreaseSeat(categoryId, discountId) {
            let index = this.cart.findIndex(
                (cartSeat) =>
                    cartSeat.categoryId === categoryId &&
                    cartSeat.discountId === discountId
            );
            if (index !== -1) {
                this.cart.splice(index, 1);
            }

            const category = this.categories.find((el) => el.id === categoryId);

            if (category) {
                if (discountId) {
                    let discount = category.discountPrices.find(
                        (elem) => elem.discount_id === discountId
                    );
                    discount.count--;
                } else {
                    category.regularPrice.count--;
                }
            }
        },
        increaseSeat(
            categoryId,
            categoryName,
            discountId,
            discountName,
            price
        ) {
            const category = this.categories.find((el) => el.id === categoryId);
            const catTickets = this.cart.filter(
                (el) => el.categoryId === categoryId
            );

            if (catTickets.length >= category.availableCount) {
                this.errorMsg = "Reached max count of available tickets.";
                return;
            }

            if (category) {
                if (discountId) {
                    let discount = category.discountPrices.find(
                        (elem) => elem.discount_id === discountId
                    );
                    discount.count++;
                } else {
                    category.regularPrice.count++;
                }
            }

            this.cart.push({
                categoryId: categoryId,
                categoryName: categoryName,
                discountId: discountId,
                discountName: discountName,
                price: price,
                manualPrice: price,
            });
        },
        removeSeat(seat) {
            let index = this.cart.findIndex(
                (cartSeat) =>
                    cartSeat.categoryId === seat.categoryId &&
                    cartSeat.discountId === seat.discountId
            );
            if (index !== -1) {
                this.cart.splice(index, 1);
            }

            const category = this.categories.find(
                (el) => el.id === seat.categoryId
            );
            if (category) {
                if (seat.discountId) {
                    let discount = category.discountPrices.find(
                        (elem) => elem.discount_id === seat.discountId
                    );
                    discount.count--;
                } else {
                    category.regularPrice.count--;
                }
            }
        },
        formatPrice(price) {
            return new Intl.NumberFormat("us-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(price);
        },
        filterTickets() {
            return this.cart.flatMap((seat) => {
                return {
                    event_id: this.event.id,
                    count: 1,
                    name: seat.discountName,
                    price: seat.price,
                    manualPrice: this.formatPrice(seat.manualPrice),
                    total: this.formatPrice(seat.manualPrice),
                    categoryId: seat.categoryId,
                    categoryName: seat.categoryName,
                    eventName: this.event.name,
                    eventDate: this.formatDate(this.event.start_date),
                    eventTime: this.event.carbon_start_time,
                    eventLocation: `${this.event.venue.city}, ${this.event.venue.country}`,
                };
            });
        },
        calculateTotal(tickets) {
            const amount = tickets.reduce(function (total, item) {
                return total + parseFloat(item.manualPrice);
            }, 0);
            return amount.toFixed(2);
        },
        convertPriceToFloat(price) {
            if (price.includes(",")) {
                price = price.replace(",", ".");
            }
            return parseFloat(price);
        },
        proceedToCheckout() {
            this.errorMsg = null;
            if (!this.cart.length) {
                this.errorMsg = "Cart is empty";
                return;
            }

            if (this.isCheckoutDisabled) {
                this.showToastMessage();
                return;
            }

            const tickets = this.filterTickets();
            const total = this.calculateTotal(tickets);

            this.bookTickets(tickets, total);
        },
        async bookTickets(tickets, total) {
            try {
                const response = await axios.post(
                    `/events/book/${this.eventId}`,
                    {
                        id: this.eventId,
                        bookingType: this.bookingType,
                        tickets_data: {
                            tickets: tickets,
                            amount: total,
                        },
                        customer_data: {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            address: this.address,
                            zip_code: this.zip_code,
                            city: this.city,
                            email: this.email,
                            phone: this.phone,
                        },
                        notes: this.notes,
                    }
                );

                if (response.status === 200) {
                    alert("Reservation was successfully!");
                    window.location.href = `/admin/orders/${response.data.order.mask_id}`;
                } else {
                    alert("Something went wrong");
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    this.errorMsg = error.response.data.message;
                    console.error("Booking error:", error.response.data.errors);
                    this.showToastMessage(
                        "Booking error:" + error.response.data.errors
                    );
                } else {
                    this.errorMsg =
                        "An unexpected error occurred. Please try again.";
                    console.error(
                        "There was an error processing the checkout:",
                        error
                    );
                    this.showToastMessage(
                        "Error booking the tickets. Please try again."
                    );
                }
            }
        },
        handleBookingTypeChange() {
            if (this.bookingType === "block_ticket") {
                this.proceedToCheckoutTxt = "Block";
            } else {
                this.proceedToCheckoutTxt = "Book";
            }
        },
        formatDate(date) {
            if (!date) return "";
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return new Date(date).toLocaleDateString("de", options);
        },
        formatTime(time) {
            return time
                ? new Date(time).toLocaleTimeString("de", {
                      hour: "numeric",
                      minute: "numeric",
                  })
                : "";
        },
        fixFormatPrice(seat) {
            // Remove any non-numeric characters except for the dot
            seat.manualPrice = seat.manualPrice.replace(/[^0-9.]/g, "");

            // Ensure the price has at most two decimal places
            seat.manualPrice = seat.manualPrice.replace(/(\.\d{2})\d+/, "$1");

            // Format the price with commas for thousands
            /*seat.manualPrice = seat.manualPrice.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
            );*/

            // If there are multiple dots, keep only the first one
            if (seat.manualPrice.includes(".")) {
                let parts = seat.manualPrice.split(".");
                if (parts.length > 2) {
                    seat.manualPrice = parts[0] + "." + parts[1];
                }
            }

            // Parse the formatted price as a float
            if (
                seat.manualPrice === "" ||
                seat.manualPrice === "NaN" ||
                seat.manualPrice === "."
            ) {
                seat.manualPrice = 0;
            }
            seat.manualPrice = parseFloat(seat.manualPrice);
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="d-flex flex-row">
            <div class="col-8 pb-3">
                <h3 class="pt-4 pb-3 question-styling">
                    Bitte wählen Sie Ihre Tickets:
                </h3>

                <div
                    v-for="category in categories"
                    class="category-card mb-3 d-flex flex-col flex-md-row justify-content-between"
                >
                    <div class="col-md-3 d-flex flex-row align-items-center">
                        <div class="d-flex flex-col">
                            <h6 class="category-title">{{ category.name }}</h6>
                            <p class="category-desc">
                                {{ category.description }}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-9 d-flex flex-col gap-4">
                        <div
                            class="d-flex flex-col flex-md-row w-100 align-items-start align-items-md-center"
                        >
                            <div class="d-flex flex-col w-100">
                                <p>Standardpreis</p>
                                <small
                                    v-if="
                                        !category.showMore &&
                                        category.discountPrices.length > 0
                                    "
                                    @click="category.showMore = true"
                                    class="show-more-options"
                                    style="
                                        color: #ffc107;
                                        text-decoration: underline;
                                    "
                                    >Mehr Preisoptionen anzeigen</small
                                >
                            </div>
                            <div class="price text-right d-flex">
                                <p class="category-title text-nowrap">
                                    € {{ category.regularPrice.price }}
                                </p>
                            </div>
                            <div class="quantity-selector">
                                <button
                                    @click="
                                        category.regularPrice.count > 0
                                            ? decreaseSeat(category.id, null)
                                            : 0
                                    "
                                    type="button"
                                    class="btn"
                                >
                                    -
                                </button>
                                <span class="px-3">{{
                                    category.regularPrice.count
                                }}</span>
                                <button
                                    @click="
                                        increaseSeat(
                                            category.id,
                                            category.name,
                                            null,
                                            'Regular',
                                            category.regularPrice.price
                                        )
                                    "
                                    type="button"
                                    class="btn"
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <hr v-if="category.showMore" />
                        <div
                            v-if="category.showMore"
                            v-for="discount in category.discountPrices"
                            class="d-flex flex-row w-100 d-flex flex-row w-100 align-items-center"
                        >
                            <div class="d-flex flex-col w-100">
                                <p>{{ discount.name }}</p>
                            </div>
                            <div class="price text-right d-flex">
                                <p class="category-title text-nowrap">
                                    € {{ discount.price }}
                                </p>
                            </div>
                            <div class="quantity-selector">
                                <button
                                    @click="
                                        discount.count > 0
                                            ? decreaseSeat(
                                                  category.id,
                                                  discount.discount_id
                                              )
                                            : 0
                                    "
                                    type="button"
                                    class="btn"
                                >
                                    -
                                </button>
                                <span class="px-3">{{ discount.count }}</span>
                                <button
                                    @click="
                                        increaseSeat(
                                            category.id,
                                            category.name,
                                            discount.discount_id,
                                            discount.name,
                                            discount.price
                                        )
                                    "
                                    type="button"
                                    class="btn"
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <small
                            v-if="category.showMore"
                            @click="category.showMore = false"
                            class="text-start show-less-options"
                            style="color: #ffc107; text-decoration: underline"
                            >Schließen
                        </small>
                    </div>
                </div>

                <div class="text-right">
                    <div
                        v-if="errorMsg"
                        class="text-danger"
                        style="text-align: center; padding: 10px 0 5px 0"
                    >
                        {{ errorMsg }}
                    </div>
                    <a
                        @click.prevent="proceedToCheckout"
                        :disabled="isCheckoutDisabled"
                        type="button"
                        class="btn-orange checkout-btn"
                        >Weiter zur Kassa</a
                    >
                </div>
            </div>
            <div class="col-4 pb-3 d-flex flex-col gap-2 align-items-end">
                <div class="selected-cats">
                    <h2 class="cart-heading">Kategorien</h2>
                    <hr />
                    <div class="d-flex flex-col pt-2 gap-2">
                        <div
                            v-for="(category, categoryIndex) in categories"
                            :key="categoryIndex"
                            class="category d-flex flex-row gap-2 justify-content-between"
                        >
                            <div class="">{{ category.name }}</div>
                            <div class="">
                                €{{ formatPrice(category.price) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="selected-seats">
                    <h2 class="cart-heading">
                        Warenkorb<span style="font-size: 16px; font-weight: 400"
                            >({{ cart.length }})</span
                        >
                    </h2>
                    <div class="form-group">
                        <label for="bookingType">Buchungsart wählen:</label>
                        <select
                            v-model="bookingType"
                            class="form-control"
                            @change="handleBookingTypeChange"
                        >
                            <option value="sell_ticket">
                                Ticket verkaufen
                            </option>
                            <option value="free_ticket">
                                Kostenloses Ticket
                            </option>
                            <option value="block_ticket">
                                Sitzplatz blockieren
                            </option>
                            <option value="box_office_ticket">
                                Abendkasse
                            </option>
                        </select>
                    </div>
                    <hr />
                    <hr />
                    <ul class="seat-list pb-2">
                        <li v-for="(seat, index) in cart" :key="index">
                            <div
                                class="d-flex col px-0 py-2 gap-2 justify-content-between"
                            >
                                <div>
                                    <p>1x {{ seat.categoryName }}</p>
                                    <div class="d-flex flex-col px-0">
                                        <small
                                            ><strong
                                                >Preis:
                                                {{
                                                    formatPrice(seat.price)
                                                }}</strong
                                            ></small
                                        >
                                        <div class="form-group">
                                            <label for="manualPrice"
                                                >Preis eingeben</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="manualPrice"
                                                @input="fixFormatPrice(seat)"
                                                v-model="seat.manualPrice"
                                                placeholder="Manual Price"
                                                :disabled="
                                                    bookingType !==
                                                        'sell_ticket' &&
                                                    bookingType !==
                                                        'box_office_ticket'
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button
                                    @click="removeSeat(seat)"
                                    class="delete-button"
                                    style=""
                                ></button>
                            </div>
                            <hr />
                        </li>
                    </ul>
                    <div v-if="cart.length > 0">
                        <button
                            @click="toggleForm"
                            class="btn btn-secondary mb-2"
                        >
                            {{ showForm ? "Felder Verstecken" : "Mehr Infos" }}
                        </button>
                        <div v-if="showForm">
                            <div class="form-group">
                                <label for="firstName">Vorname</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="first_name"
                                    v-model="first_name"
                                    placeholder="Vorname"
                                />
                            </div>
                            <div class="form-group">
                                <label for="lastName">Nachname</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="last_name"
                                    v-model="last_name"
                                    placeholder="Nachname"
                                />
                            </div>
                            <div class="form-group">
                                <label for="address">Adresse</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="address"
                                    v-model="address"
                                    placeholder="Adresse"
                                />
                            </div>
                            <div class="form-group">
                                <label for="zip_code">Postleitzahl</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="zip_code"
                                    v-model="zip_code"
                                    placeholder="Postleitzahl"
                                />
                            </div>
                            <div class="form-group">
                                <label for="city">Stadt</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="city"
                                    v-model="city"
                                    placeholder="Stadt"
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">E-mail Adresse</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    v-model="email"
                                    placeholder="E-mail Adresse"
                                />
                            </div>
                            <div class="form-group">
                                <label for="phone">Telefon</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="phone"
                                    v-model="phone"
                                    placeholder="Telefon"
                                />
                            </div>
                            <div class="form-group">
                                <label for="notes">Notiz</label>
                                <textarea
                                    class="form-control"
                                    id="notes"
                                    name="notes"
                                    v-model="notes"
                                    placeholder="Notiz"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="errorMsg"
                        class="text-danger"
                        style="text-align: center; padding: 10px 0 5px 0"
                    >
                        {{ errorMsg }}
                    </div>
                    <button
                        @click.prevent="proceedToCheckout"
                        :disabled="cart.length === 0"
                        type="button"
                        class="btn btn-orange checkout-btn w-100 mt-2"
                    >
                        <i class="fas fa-money-bill mr-2"></i>Weiter zur Kassa
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.category-card {
    display: flex;
    align-items: flex-start;
    padding: 25px 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #f8f9fa;
}
.category-card .description {
    flex-grow: 1;
    margin-left: 15px;
}
.category-card .price {
    margin-right: 15px;
}
.category-card .custom-radio {
    margin-right: 10px;
}
.quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantity-selector button {
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 5px;
    margin: 0 5px;
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    height: calc(2.75rem + 2px);
}
.checkout-btn {
    background-color: #ffc107;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
}
.btn-orange {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    height: calc(2.75rem + 2px);
}
.question-styling {
    font-size: 18px;
    font-weight: 600;
}
.category-title {
    font-size: 16px;
    font-weight: 600;
}
.category-desc {
    font-size: 14px;
    font-weight: 400;
}
.show-more-options {
    cursor: pointer;
}
.show-less-options {
    cursor: pointer;
}

/** Cart **/

.cart-heading {
    text-align: center;
    margin-bottom: 10px !important;
    font-size: 18px;
    font-weight: 600;
}
.seatmap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
}

.category-name {
    font-weight: bold;
    text-align: center;
}

.row {
    display: flex;
    justify-content: center;
}

.seat {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.seat:hover {
    background-color: #ddd;
}

.seat.aisle {
    background-color: unset;
    border: none;
    cursor: default;
}

.seat.selected {
    background-color: #4caf50;
    color: white;
}

.seat.booked {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}

.selected-seats {
    position: relative;
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-height: 550px;
    overflow: scroll;
}

.selected-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    top: 0;
    right: 10px;
}

.selected-cats {
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.selected-seats h2 {
    text-align: center;
    margin-bottom: 20px;
}

.delete-button {
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #d9d9d9;
    border-radius: 10px;
    color: black;
}

.delete-button:before {
    content: "\2715";
    font-size: 16px;
}
.checkout-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.btn-orange {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    height: calc(2.75rem + 2px);
    font-weight: 600;
    color: white;
}

@media (max-width: 480px) {
    .selected-seats {
        position: fixed;
        bottom: 0;
        top: unset;
        right: 0;
        width: 100%;
        max-height: 330px;
        background-color: white;
        border: 1px solid #ddd;
        padding: 20px;
        z-index: 1;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .seat-list {
        overflow: scroll;
        max-height: 150px;
    }

    .seat-overflow {
        flex-wrap: nowrap !important;
        overflow: visible;
    }
    .seatmap {
        overflow: scroll;
        align-items: baseline;
    }
    .selected-box {
        position: relative;
        right: 0;
    }
}
</style>
