<script>
import axios from "axios";

export default {
    name: "SeatPlan",
    props: {
        eventId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            discounts: [],
            seatCategories: [],
            categories: [],
            bookings: [],
            boughtTickets: [],
            cart: [],
            errorMsg: "",
            event: {},
            first_name: "",
            svgScale: 0.75,
            dragOffsetX: 0,
            dragOffsetY: 0,
            last_name: "",
            address: "",
            zip_code: "",
            city: "",
            email: "",
            phone: "",
            notes: "",
            bookingType: "sell_ticket",
            seatType: "normal",
            proceedToCheckoutTxt: "Buchen",
            visible: true,
            loading: true,
            showForm: false,
        };
    },
    computed: {
        selectedSeats() {
            return this.cart;
        },
        isFreeTicket() {
            return this.bookingType === "free_ticket";
        },
        isBlockTicket() {
            return this.bookingType === "block_ticket";
        },
        statusControl() {
            if (this.isBlockTicket) this.seatType = this.seatType;
            else this.seatType = "normal";
        },
        showStatusSelect() {
            return this.bookingType === "block_ticket";
        },
    },
    created() {
        this.fetchEvent(this.eventId);
    },
    mounted() {
        this.$nextTick(() => {
            const rects = document.querySelectorAll("rect");
            rects.forEach((rect) => {
                rect.addEventListener("click", this.toggleSeatSelection);
            });
        });
        this.$nextTick(() => {
            // Existing mounted logic...
            window.addEventListener("mouseup", this.endDrag);
        });
    },
    beforeDestroy() {
        window.removeEventListener("mouseup", this.endDrag);
    },
    watch: {
        seatCategories(newVal, oldVal) {
            if (newVal) {
                // Trigger the function when svgURL state has data
                // this.markAlreadyBookedSeatsDisabled();
            }
        },
        bookingType(newVal) {
            if (newVal === "free_ticket") {
                this.cart.forEach((seat) => {
                    seat.manualPrice = 0;
                });
            }
        },
    },
    methods: {
        toggleForm() {
            this.showForm = !this.showForm;
        },
        async fetchEvent(eventId) {
            try {
                const response = await axios.post(`/api/events/${eventId}`);
                // Set the svg content from the api call in the vue state
                await this.setSvgContent(response.data);
                this.processEventData(response.data);

                // this.markAlreadyBookedSeatsDisabled();
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        },
        setSvgContent(data) {
            try {
                let { seatmap_path } = data;
                // let svgUrlFromApi = svg_map_file?.original_url
                let svgUrlFromApi = seatmap_path;
                this.svgURL = svgUrlFromApi;
                this.fetchSvgContent(svgUrlFromApi);
            } catch (error) {
                console.error("Error in setting svg url", error);
            }
        },
        async fetchSvgContent(svgUrl) {
            try {

                // Extract the relative path

                let relativePath = new URL(svgUrl).pathname;

                const response = await fetch(relativePath);
                if (!response.ok) {
                    throw new Error("Failed to fetch SVG");
                }
                const svgContent = await response.text();

                this.renderSvgContent(svgContent);
            } catch (error) {
                console.error("Error fetching SVG:", error);
            }
        },
        markAlreadyBookedSeatsDisabled() {
            // setTimeout(() => {

            // Disable tickets which are already booked
            this.bookings.forEach((booking, currentIndex) => {
                let seatNunmber = booking.seat;
                let row = booking.row;
                let category_key = this.findNameById(
                    this.seatCategories,
                    booking.event_seat_plan_category_id
                );
                let categoryWithPrefix = `cat-${category_key}`;

                let category_price = this.findPriceById(
                    this.seatCategories,
                    booking.event_seat_plan_category_id
                );

                let parentDiv = document.getElementById(categoryWithPrefix);
                // Select the <g> element containing id attribute with "row_1"
                let groupElement = parentDiv.querySelector(`g[id*="r${row}"]`);

                let rectGroupElement = groupElement.querySelector(
                    `rect[id*="s${seatNunmber}_"]`
                );
                if (rectGroupElement) {
                    if (
                        boughtTicket.orderType == "admin" ||
                        boughtTicket.paymentType == "Offline"
                    ) {
                        rectGroupElement.classList.add("unpaid");
                    }

                    // Remove the 'selected' class from rectGroupElement
                    // rectGroupElement.classList.add('selected');
                    if (boughtTicket.seatType == "assigned") {
                        rectGroupElement.classList.add("assigned");
                    } else if (boughtTicket.seatType == "unassigned") {
                        rectGroupElement.classList.add("unassigned");
                    } else if (boughtTicket.seatType == "hidden") {
                        rectGroupElement.classList.add("hidden");
                    } else {
                        rectGroupElement.classList.add("booked");
                    }

                    let notes = "";
                    if (boughtTicket.orderType == "admin") {
                        notes = "Admin Note: " + boughtTicket.notes;
                    }

                    let toolTipHoverTitle = `#${boughtTicket.orderId}, ${
                        boughtTicket.firstName
                    } ${boughtTicket.lastName}, Category ${
                        boughtTicket.categoryName
                    }, Row ${boughtTicket.row}, Seat ${
                        boughtTicket.seat
                    }, Price €${this.formatPrice(category_price)}, ${notes}`;

                    // Add tooltip attributes
                    rectGroupElement.setAttribute("data-toggle", "tooltip");
                    rectGroupElement.setAttribute("data-placement", "top");
                    rectGroupElement.setAttribute("title", toolTipHoverTitle);

                    // Initialize the tooltip
                    new bootstrap.Tooltip(rectGroupElement);

                    // Remove pointer-events: none
                    rectGroupElement.style.pointerEvents = "auto";

                    if (rectGroupElement.classList.contains("unpaid")) {
                        let cancelBtn = document.createElement("button");
                        cancelBtn.classList.add("btn", "btn-danger", "btn-sm");
                        cancelBtn.innerText = "Cancel";
                        cancelBtn.style.position = "absolute";
                        cancelBtn.style.top = "0";
                        cancelBtn.style.right = "0";
                        cancelBtn.style.zIndex = "100";
                        cancelBtn.style.borderRadius = "0";
                        cancelBtn.style.padding = "0.25rem 0.5rem";
                        cancelBtn.style.fontSize = "0.75rem";
                        cancelBtn.style.opacity = "0.8";
                        cancelBtn.style.pointerEvents = "auto";
                        cancelBtn.style.cursor = "pointer";
                        cancelBtn.style.border = "none";
                        cancelBtn.style.color = "white";
                        cancelBtn.style.backgroundColor = "red";
                        cancelBtn.style.display = "none";
                        rectGroupElement.parentElement.appendChild(cancelBtn);
                        rectGroupElement.addEventListener("mouseenter", () => {
                            cancelBtn.style.display = "block";
                        });
                        rectGroupElement.addEventListener("mouseleave", () => {
                            cancelBtn.style.display = "none";
                        });
                        cancelBtn.addEventListener("click", () => {
                            this.cancelTicket(boughtTicket.ticketId);
                        });
                    }
                }
            });

            // Disable the already bought tickets
            this.boughtTickets.forEach((boughtTicket, currentIndex) => {
                let seatNunmber = boughtTicket.seat;
                let row = boughtTicket.row;
                let category_key = boughtTicket.category_key;
                let categoryWithPrefix = `cat-${category_key}`;

                let category_price = this.findPriceById(
                    this.seatCategories,
                    boughtTicket.categoryId
                );

                let parentDiv = document.getElementById(categoryWithPrefix);
                // Select the <g> element containing id attribute with "row_1"
                let groupElement = parentDiv.querySelector(`g[id*="r${row}"]`);

                let rectGroupElement = groupElement.querySelector(
                    `rect[id*="s${seatNunmber}_"]`
                );
                if (rectGroupElement) {
                    let notes = "";
                    if (boughtTicket.orderType == "admin") {
                        if (boughtTicket.notes != null)
                            notes = ", Admin Note: " + boughtTicket.notes;
                    }

                    // Remove the 'selected' class from rectGroupElement
                    // rectGroupElement.classList.add('selected');
                    let toolTipHoverTitle = `#${boughtTicket.orderId}, ${
                        boughtTicket.firstName
                    } ${boughtTicket.lastName}, Category ${
                        boughtTicket.categoryName
                    }, Row ${boughtTicket.row}, Seat ${
                        boughtTicket.seat
                    }, Price €${this.formatPrice(category_price)}${notes}`;

                    if (
                        boughtTicket.orderType == "admin" ||
                        boughtTicket.paymentType == "Offline"
                    ) {
                        rectGroupElement.classList.add("unpaid");
                    }

                    if (boughtTicket.seatType == "assigned") {
                        rectGroupElement.classList.add("assigned");
                    } else if (boughtTicket.seatType == "unassigned") {
                        rectGroupElement.classList.add("unassigned");
                    } else if (boughtTicket.seatType == "hidden") {
                        rectGroupElement.classList.add("hidden");
                    } else {
                        rectGroupElement.classList.add("booked");
                    }

                    // Add tooltip attributes
                    rectGroupElement.setAttribute("data-toggle", "tooltip");
                    rectGroupElement.setAttribute("data-placement", "top");
                    rectGroupElement.setAttribute("title", toolTipHoverTitle);

                    // Initialize the tooltip
                    new bootstrap.Tooltip(rectGroupElement);

                    // Remove pointer-events: none
                    rectGroupElement.style.pointerEvents = "auto";

                    rectGroupElement.innerHTML += `
                        <div v-if="boughtTicket.ticketId" class="dropdown hidden">
                            <button
                                class="btn btn-light btn-sm dropdown-toggle"
                                type="button"
                                id="cancelDropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                            <div
                                class="dropdown-menu"
                                aria-labelledby="cancelDropdown"
                            >
                                <a
                                    v-if="
                                        seat.orderType == 'admin' &&
                                        seat.paymentMethod == 'Offline'
                                    "
                                    @click="cancelTicket(boughtTicket.ticketId)"
                                    class="dropdown-item"
                                    type="button"
                                    >Delete ticket</a
                                >
                                <a v-else class="dropdown-item" type="button"
                                    >No action</a
                                >
                            </div>
                        </div>`;
                }
            });

            // }, 2000);
        },
        addTooltipToElement(element, tooltipText) {
            if (element) {
                element.setAttribute("title", tooltipText);

                // Event listener for mouse enter
                element.addEventListener("mouseenter", function () {
                    this.style.cursor = "not-allowed";

                    const tooltip = document.createElement("div");
                    tooltip.className = "custom-tooltip";
                    tooltip.innerText = this.getAttribute("title");
                    document.body.appendChild(tooltip);

                    const rect = this.getBoundingClientRect();
                    tooltip.style.left = `${rect.left + window.scrollX}px`;
                    tooltip.style.top = `${
                        rect.top + window.scrollY - tooltip.offsetHeight
                    }px`;

                    this._tooltip = tooltip;
                });

                // Event listener for mouse leave
                element.addEventListener("mouseleave", function () {
                    if (this._tooltip) {
                        document.body.removeChild(this._tooltip);
                        this._tooltip = null;
                    }
                });
            }
        },
        findNameById(data, id) {
            let foundItem = data.find((item) => item.id === id);
            let keyToReturn = foundItem ? foundItem.category_key : null;
            return keyToReturn;
        },
        findPriceById(data, id) {
            let foundItem = data.find((item) => item.id === id);
            let keyToReturn = foundItem ? foundItem.price : null;
            return keyToReturn;
        },
        renderSvgContent(svgContent) {
            // Inject SVG content into the div
            const svgMapDiv = document.getElementById("svgMapId");

            svgMapDiv.innerHTML = this.stripSvgTag(svgContent);

            // Get width, height and viewBox of svg uploaded
            let { width, height, viewBox } =
                this.extractSVGAttributes(svgContent);

            // Add class and inline styles to the SVG
            const svgElement = svgMapDiv; // Assuming the top-level element is <svg>

            if (svgElement) {
                //if (width) svgElement.setAttribute('width', width);
                //if (height) svgElement.setAttribute('height', height);
                if (viewBox) svgElement.setAttribute("viewBox", viewBox);
            }

            // Add event listener for each rectangle
            const rects = document.querySelectorAll("rect");
            rects.forEach((rect) => {
                rect.addEventListener("click", (event) => {
                    // Get the clicked element
                    const clickedElement = event.target;

                    // Check if the clicked element has the 'booked' class
                    if (
                        clickedElement.classList.contains(
                            "booked" || "assigned" || "unassigned" || "hidden"
                        )
                    ) {
                        // If the element is booked, exit the function
                        return;
                    } else {
                        // Otherwise, call the toggleSeatSelection function
                        this.toggleSeatSelection(event);
                    }
                });
            });

            this.loadDynamicStyles();

            this.loading = false;
            this.visible = true;
            this.markAlreadyBookedSeatsDisabled();
        },
        extractSVGAttributes(svgContent) {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");
            const svgElement = svgDoc.querySelector("svg");

            if (svgElement) {
                // Get the width and height
                const width = svgElement.getAttribute("width");
                const height = svgElement.getAttribute("height");

                // Get the viewBox attribute
                const viewBox = svgElement.getAttribute("viewBox");

                // console.log('Width:', width);
                // console.log('Height:', height);
                // console.log('ViewBox:', viewBox);

                // Append the SVG to the DOM
                return {
                    width,
                    height,
                    viewBox,
                };
            } else {
                return null;
            }
        },
        stripSvgTag(svgContent) {
            // Create a temporary DOM element to parse the SVG content
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = svgContent;

            // Get the <svg> element
            const svgElement = tempDiv.querySelector("svg");

            // If an <svg> element exists, return its inner HTML
            if (svgElement) {
                return svgElement.innerHTML;
            }

            // If no <svg> element is found, return the original content
            return svgContent;
        },
        getGrandParentId(element) {
            if (!element) return null;

            const parent = element.parentElement;
            if (!parent) return null;

            const grandParent = parent.parentElement;
            if (!grandParent) return null;

            const greatGrandParent = grandParent.parentElement;
            if (!greatGrandParent) return null;

            return greatGrandParent.id || null;
        },
        getIds(element) {
            const ids = {
                parentId: null,
                grandParentId: null,
                greatGrandParentId: null,
            };

            if (!element) return ids;

            const parent = element.parentElement;
            if (parent) {
                ids.parentId = parent.id || null;

                const grandParent = parent.parentElement;
                if (grandParent) {
                    ids.grandParentId = grandParent.id || null;

                    const greatGrandParent = grandParent.parentElement;
                    if (greatGrandParent) {
                        ids.greatGrandParentId = greatGrandParent.id || null;
                    }
                }
            }

            return ids;
        },
        toggleSeatSelection(event) {
            const rect = event.target;

            let idOfSelectedRect = rect.id;

            // Extract the number before the underscore
            let numberBeforeUnderscore = idOfSelectedRect
                .split("_")[0]
                .substr(1);

            let seatNumber = numberBeforeUnderscore;

            // Get IDs of parent, grandparent, and great-grandparent
            const ids = this.getIds(rect);

            // Log the IDs for testing or further processing
            // console.log("Parent ID:", ids.parentId);
            // console.log("Grandparent ID:", ids.grandParentId);
            // console.log("Great-grandparent ID:", ids.greatGrandParentId);

            // Example string
            const grandParentId = ids.grandParentId;

            // Extract the number before '_'
            const parts = grandParentId.split("_");
            const rowNumber = parts[0].substring(1); // Remove the 'r' prefix

            let categoryName = ids.greatGrandParentId;
            let correspondingCategory = this.findCategory(categoryName);

            if (rect.tagName !== "rect") {
                return; // Ensure only <rect> elements are targeted
            }

            let seat = {};

            if (rect.classList.contains("selected")) {
                rect.classList.remove("selected");

                seat = {
                    booked: true,
                    categoryId: correspondingCategory.id,
                    categoryIndex: 1,
                    categoryName: correspondingCategory.name,
                    number: parseInt(rowNumber),
                    seatNumber: parseInt(seatNumber),
                    selected: true,
                    category_key: correspondingCategory.category_key,
                };

                let index = this.cart.findIndex((cartSeat) => {
                    return (
                        cartSeat.number === seat.number &&
                        cartSeat.seatNumber === seat.seatNumber &&
                        cartSeat.categoryIndex === seat.categoryIndex
                    );
                });

                if (index !== -1) {
                    this.cart.splice(index, 1);
                }
            } else {
                seat = {
                    booked: true,
                    categoryId: correspondingCategory.id,
                    categoryIndex: 1,
                    categoryName: correspondingCategory.name,
                    number: parseInt(rowNumber),
                    seatNumber: parseInt(seatNumber),
                    selected: true,
                    category_key: correspondingCategory.category_key,
                };

                this.cart.push({
                    ...seat,
                    price: correspondingCategory.price,
                    prices: correspondingCategory.prices,
                });

                rect.classList.add("selected");
            }
        },
        findCategory(categoryName) {
            const nameToFind = categoryName.replace("cat-", "");
            return this.seatCategories.find(
                (category) => category.category_key === nameToFind
            );
        },
        zoomIn() {
            if (window.innerWidth <= 480)
                this.svgScale = Math.min(this.svgScale + 0.1, 4);
            // Max scale 4x for mobile
            else this.svgScale = Math.min(this.svgScale + 0.1, 2); // Max scale 2x
        },
        zoomOut() {
            this.svgScale = Math.max(this.svgScale - 0.1, 0.5); // Min scale 0.5x
        },
        oneToOne() {
            //const svg = this.$el.querySelector('svg.draggable');
            //const containerWidth = svg.parentElement.clientWidth;
            //const svgWidth = svg.viewBox.baseVal.width;
            //this.svgScale = containerWidth / svgWidth - 0.025;
            this.svgScale = 0.9;
            this.dragOffsetX = 0;
            this.dragOffsetY = 0;
            this.updateSVGPosition();

            if (window.innerWidth <= 480) return;
            this.hideAndShow();
        },
        hideAndShow() {
            const selectedBox = document.querySelector(".selected-box");
            const slideSelectBox = document.getElementById("slideSelectBox");
            const selectedBoxWidth = selectedBox.offsetWidth;
            const slideSelectBoxWidth = slideSelectBox.offsetWidth;

            if (selectedBox.dataset.visibility === "show") {
                selectedBox.style.transform = `translate(${selectedBoxWidth}px, 0)`;
                selectedBox.dataset.visibility = "hide";
                slideSelectBox.innerHTML = '<i class="fas fa-arrow-left"></i>';
            } else {
                selectedBox.style.transform = "translate(0, 0)";
                selectedBox.dataset.visibility = "show";
                slideSelectBox.innerHTML = '<i class="fas fa-arrow-right"></i>';
            }
        },
        fullScreen(getEl) {
            if (getEl === undefined) return;

            window.scrollTo(0, 0);
            if (getEl.classList.contains("full-screen")) {
                getEl.classList.remove("full-screen");
                document.getElementById("myTabContent").style.width = "unset";
                document.getElementById("myTabContent").style.height = "unset";
                document.getElementById("myTabContent").style.position =
                    "relative";
                document.getElementById("myTabContent").style.top = "unset";
                document.getElementById("myTabContent").style.left = "unset";
                document.getElementById("myTabContent").style.zIndex = "unset";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.width = "unset";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.height = "unset";
                document.getElementById("fullScreen").innerHTML =
                    '<i class="fas fa-expand"></i>';
            } else {
                getEl.classList.add("full-screen");
                document.getElementById("myTabContent").style.width = "100%";
                document.getElementById("myTabContent").style.height = "100%";
                document.getElementById("myTabContent").style.position =
                    "absolute";
                document.getElementById("myTabContent").style.top = "0";
                document.getElementById("myTabContent").style.left = "0";
                document.getElementById("myTabContent").style.zIndex = "9999";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.width = "100%";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.height = "100%";
                document.getElementById("fullScreen").innerHTML =
                    '<i class="fas fa-compress"></i>';
            }
        },
        startDrag(event) {
            this.isDragging = true;
            if (event.type === "mousedown") {
                this.startX = event.clientX - this.dragOffsetX;
                this.startY = event.clientY - this.dragOffsetY;
            } else if (event.type === "touchstart") {
                document.body.style.overflow = "hidden";
                if (event.touches.length < 2) {
                    this.startX = event.touches[0].clientX - this.dragOffsetX;
                    this.startY = event.touches[0].clientY - this.dragOffsetY;
                } else {
                    event.preventDefault();
                    this.startX =
                        Math.abs(
                            event.touches[0].clientX - event.touches[1].clientX
                        ) / 2;
                    this.startY =
                        Math.abs(
                            event.touches[0].clientY - event.touches[1].clientY
                        ) / 2;
                    this.startScale = this.svgScale;
                    this.startDistance = Math.hypot(
                        event.touches[0].clientX - event.touches[1].clientX,
                        event.touches[0].clientY - event.touches[1].clientY
                    );
                    this.startAngle = Math.atan2(
                        event.touches[0].clientY - event.touches[1].clientY,
                        event.touches[0].clientX - event.touches[1].clientX
                    );
                    this.startAngle = (this.startAngle * 180) / Math.PI;

                    this.isPinchZooming = true;
                }
            }
        },
        onDrag(event) {
            if (!this.isDragging) return;
            if (event.type === "mousemove") {
                this.dragOffsetX = event.clientX - this.startX;
                this.dragOffsetY = event.clientY - this.startY;
            } else if (event.type === "touchmove") {
                event.preventDefault();
                if (event.touches.length < 2) {
                    this.dragOffsetX = event.touches[0].clientX - this.startX;
                    this.dragOffsetY = event.touches[0].clientY - this.startY;
                } else {
                    const distance = Math.hypot(
                        event.touches[0].clientX - event.touches[1].clientX,
                        event.touches[0].clientY - event.touches[1].clientY
                    );
                    const scale = distance / this.startDistance;
                    this.svgScale = this.startScale * scale;
                    const angle = Math.atan2(
                        event.touches[0].clientY - event.touches[1].clientY,
                        event.touches[0].clientX - event.touches[1].clientX
                    );
                    this.startAngle = (angle * 180) / Math.PI;
                }
            }
            this.updateSVGPosition();
        },
        endDrag() {
            this.isDragging = false;
            document.body.style.overflow = "auto";
            this.isPinchZooming = false;
            if (this.svgScale < 0.5 || this.svgScale > 4) {
                if (this.svgScale < 0.5) this.svgScale = 0.5;
                else if (this.svgScale > 4) this.svgScale = 4;
                this.updateSVGPosition();
            }
        },
        updateSVGPosition() {
            const svg = this.$el.querySelector("svg.draggable");
            svg.style.transform = `translate(${this.dragOffsetX}px, ${this.dragOffsetY}px) scale(${this.svgScale})`;
        },
        processEventData(data) {
            this.event = data;
            this.bookings = data.bookings;
            this.processDiscounts(data.discounts);
            this.seatCategories = this.generateSeatCategories(
                data.seat_plan_categories
            );
            this.boughtTickets = this.generateBoughtTickets(data.orders);

            console.log("boughtTickets--->", this.boughtTickets);
        },
        processDiscounts(discounts) {
            this.discounts = discounts.map((discount) => ({
                id: discount.id,
                name: discount.name,
                type: discount.type,
                fixed: discount.fixed,
                percentage: discount.percentage,
                description: discount.description,
            }));
        },
        generateBoughtTickets(orders) {
            const boughtTickets = [];
            orders.forEach((order) => {
                if (
                    order.order_status === "succeeded" ||
                    (order.order_status === "new" && order.wait_to_payment)
                ) {
                    const tickets = order.tickets
                        .filter((ticket) => {
                            return !ticket.is_cancelled && !ticket.is_refunded;
                        })
                        .map((ticket) => ({
                            orderId: order.id,
                            firstName: order.first_name,
                            lastName: order.last_name,
                            ticketId: ticket.id,
                            categoryId: ticket.event_seat_plan_category_id,
                            categoryName: ticket.category_name,
                            isPaid: ticket.is_paid,
                            category_key: this.findNameById(
                                this.seatCategories,
                                ticket.event_seat_plan_category_id
                            ),
                            row: ticket.row,
                            seat: ticket.seat,
                            orderType: order.order_type,
                            paymentType: order.payment_type,
                            seatType: order.seat_type,
                            notes: order.notes,
                        }));
                    boughtTickets.push(...tickets);
                }
            });
            return boughtTickets;
        },
        generateSeatCategories(categories) {
            return categories.map((category, categoryIndex) => {
                const aislesAfterArr = category.aisles_after
                    .split(",")
                    .map(Number);
                const discountPrices = this.calculateDiscountPrices(category);
                const regularPrice = {
                    name: "Regular",
                    price: category.price,
                    count: 0,
                };

                return {
                    id: category.id,
                    name: category.name,
                    category_key: category.category_key,
                    price: category.price, // uses by default
                    prices: [regularPrice, ...discountPrices],
                    rows: Array(category.rows)
                        .fill()
                        .map((_, rowIndex) => {
                            return this.generateRow(
                                category.seats,
                                aislesAfterArr,
                                rowIndex + 1,
                                categoryIndex + 1,
                                category.id,
                                category.name
                            );
                        }),
                };
            });
        },
        calculateDiscountPrices(category) {
            return this.discounts.map((discount) => {
                const price =
                    discount.type === "percentage"
                        ? category.price -
                          category.price * (discount.percentage / 100)
                        : category.price - discount.fixed;

                return {
                    discount_id: discount.id,
                    name: discount.name,
                    price: price,
                    count: 0,
                };
            });
        },
        selectSeat(categoryIndex, rowIndex, seatIndex) {
            const category = this.seatCategories[categoryIndex];
            let seat = category.rows[rowIndex][seatIndex];
            seat.selected = !seat.selected;
            if (seat.selected) {
                seat.manualPrice = category.price;
                this.cart.push({
                    ...seat,
                    price: this.seatCategories[categoryIndex].price,
                    prices: category.prices,
                });
            } else {
                let index = this.cart.findIndex(
                    (cartSeat) =>
                        cartSeat.number === seat.number &&
                        cartSeat.rowNumber === seat.rowNumber &&
                        cartSeat.categoryIndex === seat.categoryIndex
                );
                if (index !== -1) {
                    this.cart.splice(index, 1);
                }
            }
        },
        removeSeat(seat) {
            console.log("seat--->", seat);
            let seatNunmber = seat.seatNumber;
            let row = seat.number;
            let category_key = seat.category_key;
            let categoryWithPrefix = `cat-${category_key}`;

            let parentDiv = document.getElementById(categoryWithPrefix);
            console.log("parentDiv-->", parentDiv);

            // Select the <g> element containing id attribute with "row_1"
            let groupElement = parentDiv.querySelector(`g[id*="r${row}_"]`);

            let rectGroupElement = groupElement.querySelector(
                `rect[id*="s${seatNunmber}_"]`
            );

            if (rectGroupElement) {
                // Remove the 'selected' class from rectGroupElement
                rectGroupElement.classList.remove("selected");
            }

            let index = this.cart.findIndex((cartSeat) => {
                return (
                    cartSeat.number === seat.number &&
                    cartSeat.seatNumber === seat.seatNumber &&
                    cartSeat.categoryIndex === seat.categoryIndex
                );
            });

            if (index !== -1) {
                this.cart.splice(index, 1);
            }
        },
        proceedToCheckout() {
            this.errorMsg = null;
            if (!this.cart.length) {
                this.errorMsg = "Cart is empty";
                return;
            }

            const tickets = this.filterTickets();
            const total = this.calculateTotal(tickets);

            // Cookies.set('cart_tickets', JSON.stringify(tickets));
            // Cookies.set('cart_total', total);
            // Cookies.set('cart_event', this.eventId);

            this.bookTickets(tickets, total);
        },
        filterTickets() {
            return this.cart.flatMap((category) => {
                return {
                    event_id: this.event.id,
                    count: 1,
                    name: "Regular",
                    price: category.price,
                    manualPrice: this.formatPrice(category.manualPrice),
                    prices: category.prices, // uses when there are some discounts
                    row: category.number,
                    seat: category.seatNumber,
                    total: this.formatPrice(category.manualPrice),
                    categoryId: category.categoryId,
                    categoryName: category.categoryName,
                    eventName: this.event.name,
                    eventDate: this.formatDate(this.event.start_date),
                    eventTime: this.event.carbon_start_time,
                    eventLocation: `${this.event.venue.city}, ${this.event.venue.country}`,
                };
            });
        },
        calculateTotal(tickets) {
            const amount = tickets.reduce(
                (total, item) => total + item.manualPrice,
                0
            );
            return this.formatPrice(amount);
        },
        async bookTickets(tickets, total) {
            try {
                var category = this.seatCategories.find(
                    (category) => category.id === tickets[0].categoryId
                );
                const response = await axios.post(
                    `/events/book/${this.eventId}`,
                    {
                        id: this.eventId,
                        bookingType: this.bookingType,
                        tickets_data: {
                            tickets: tickets,
                            amount: total === "NaN" ? category.price : total,
                            //amount: bookingType === 'sell_ticket' ? total === "NaN" ? category.price : total : 0,
                        },
                        customer_data: {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            address: this.address,
                            zip_code: this.zip_code,
                            city: this.city,
                            email: this.email,
                            phone: this.phone,
                        },
                        notes: this.notes,
                        seatType: this.seatType,
                    }
                );

                if (response.status === 200) {
                    alert("Reservation was successfully!");
                    window.location.href = `/admin/orders/${response.data.order.mask_id}`;
                } else {
                    alert("Something went wrong");
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    this.errorMsg = error.response.data.message;
                    console.error("Booking error:", error.response.data.errors);
                    this.showToastMessage(
                        "Booking error:" + error.response.data.errors
                    );
                } else {
                    this.errorMsg =
                        "An unexpected error occurred. Please try again.";
                    console.error(
                        "There was an error processing the checkout:",
                        error
                    );
                    this.showToastMessage(
                        "Error booking the tickets. Please try again."
                    );
                }
            }
        },
        handleChangePrice(index) {
            const seat = this.selectedSeats[index];
            seat.manualPrice = seat.price;
        },
        formatPrice(price) {
            return new Intl.NumberFormat("us-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(price);
        },
        /*formatDate(date) {
            return date ? new Date(date).toLocaleDateString('de', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }) : '';
        },*/
        formatDate(date) {
            if (!date) return "";
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return new Date(date).toLocaleDateString("de", options);
        },
        formatTime(time) {
            return time
                ? new Date(time).toLocaleTimeString("de", {
                      hour: "numeric",
                      minute: "numeric",
                  })
                : "";
        },
        fixFormatPrice(seat) {
            // Remove any non-numeric characters except for the dot
            seat.manualPrice = seat.manualPrice.replace(/[^0-9.]/g, "");

            // Ensure the price has at most two decimal places
            seat.manualPrice = seat.manualPrice.replace(/(\.\d{2})\d+/, "$1");

            // Format the price with commas for thousands
            /*seat.manualPrice = seat.manualPrice.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
            );*/

            // If there are multiple dots, keep only the first one
            if (seat.manualPrice.includes(".")) {
                let parts = seat.manualPrice.split(".");
                if (parts.length > 2) {
                    seat.manualPrice = parts[0] + "." + parts[1];
                }
            }

            // Parse the formatted price as a float
            if (
                seat.manualPrice === "" ||
                seat.manualPrice === "NaN" ||
                seat.manualPrice === "."
            ) {
                seat.manualPrice = 0;
            }
            seat.manualPrice = parseFloat(seat.manualPrice);
        },
        loadDynamicStyles() {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = "/css/svgStyles.css";
            document.head.appendChild(link);
        },
        cancelTicket(ticketId) {
            const confirmed = confirm("Are you sure you want to cancel?");
            if (confirmed) {
                axios
                    .get(`/admin/orders/${ticketId}/cancel`)
                    .then((response) => {
                        if (response.status === 200) {
                            this.fetchEvent(this.eventId);
                            this.fetchTooltipTickets(this.eventId);
                        } else {
                            alert("Something went wrong");
                        }
                    })
                    .catch((error) => {
                        console.error("Error cancelling ticket:", error);
                    });
            }
        },
    },
};
</script>

<template>
    <div>
        <!-- Loading Spinner -->
        <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center"
            style="height: 50vh"
        >
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- Main Content -->

        <div :class="{ invisible: !visible }">
            <div class="seatmap overflow-hidden absolute">
                <svg
                    class="draggable"
                    :style="{
                        transform:
                            'translate(' +
                            dragOffsetX +
                            'px,' +
                            dragOffsetY +
                            'px) scale(' +
                            svgScale +
                            ')',
                    }"
                    @mousedown="startDrag"
                    @mousemove="onDrag"
                    @mouseup="endDrag"
                    @mouseleave="endDrag"
                    @touchstart="startDrag"
                    @touchmove="onDrag"
                    @touchend="endDrag"
                    @touchcancel="endDrag"
                    width="100%"
                    height="100%"
                    viewBox="0 0 1000 1000"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    id="svgMapId"
                ></svg>
            </div>

            <div class="box-controls">
                <button
                    class="btn-orange px-3 rounded"
                    @click="fullScreen($event.target)"
                    id="fullScreen"
                >
                    <i class="fas fa-expand"></i>
                </button>
                <button
                    class="btn-orange px-3 rounded ml-2"
                    @click="hideAndShow"
                    id="slideSelectBox"
                >
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
            <div class="selected-box overflow-auto">
                <div class="zoom-controls w-100 d-flex justify-content-between">
                    <button class="btn-orange px-3 rounded" @click="zoomIn">
                        Zoom In
                    </button>
                    <button class="btn-orange px-3 rounded" @click="oneToOne">
                        1:1
                    </button>
                    <button class="btn-orange px-3 rounded" @click="zoomOut">
                        Zoom Out
                    </button>
                </div>
                <div class="selected-cats">
                    <h2 class="cart-heading">Kategorien</h2>
                    <hr />
                    <div class="d-flex flex-col pt-2 gap-2">
                        <div
                            v-for="(category, categoryIndex) in seatCategories"
                            :key="categoryIndex"
                            class="category d-flex flex-row gap-2 justify-content-between"
                        >
                            <div class="">{{ category.name }}</div>
                            <div class="">
                                €{{ formatPrice(category.price) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="selected-seats">
                    <h2 class="cart-heading">Warenkorb</h2>
                    <hr />
                    <div class="form-group pt-2">
                        <label for="bookingType">Buchungsart wählen:</label>
                        <select
                            v-model="bookingType"
                            class="form-control"
                            @change="handleBookingTypeChange, statusControl"
                        >
                            <option value="sell_ticket">
                                Ticket verkaufen
                            </option>
                            <option value="free_ticket">
                                Kostenloses Ticket
                            </option>
                            <option value="block_ticket">
                                Sitzplatz blockieren
                            </option>
                            <option value="box_office_ticket">
                                Abendkasse
                            </option>
                        </select>
                    </div>
                    <div class="form-group pt-2" v-if="showStatusSelect">
                        <label for="status">Status wählen:</label>
                        <select
                            v-model="seatType"
                            class="form-control"
                            id="status"
                            name="status"
                        >
                            <option value="normal">Normal</option>
                            <option value="assigned">Assigned</option>
                            <option value="unassigned">Unassigned</option>
                            <option value="hidden">Hidden</option>
                        </select>
                    </div>
                    <hr />
                    <ul class="seat-list pb-2">
                        <li v-for="(seat, index) in selectedSeats" :key="index">
                            <div
                                class="d-flex col px-0 py-2 gap-2 justify-content-between"
                            >
                                <div>
                                    <p>1x {{ seat.categoryName }}</p>
                                    <div class="d-flex flex-col px-0">
                                        <small
                                            >Reihe: {{ seat.number }}, Platz:
                                            {{ seat.seatNumber }}</small
                                        >
                                        <small v-if="seat.prices.length === 1"
                                            ><strong
                                                >Preis:
                                                {{
                                                    formatPrice(seat.price)
                                                }}</strong
                                            ></small
                                        >
                                        <div v-if="seat.prices.length > 1">
                                            <label>Preis: </label>
                                            <select
                                                v-model="seat.price"
                                                class="form-control"
                                                @change="
                                                    handleChangePrice(index)
                                                "
                                                :disabled="
                                                    bookingType !==
                                                        'sell_ticket' &&
                                                    bookingType !==
                                                        'box_office_ticket'
                                                "
                                            >
                                                <option
                                                    v-for="(
                                                        price, idx
                                                    ) in seat.prices"
                                                    :key="idx"
                                                    :value="price.price"
                                                >
                                                    €{{
                                                        formatPrice(price.price)
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="manualPrice"
                                                >Preis eingeben</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="manualPrice"
                                                @input="fixFormatPrice(seat)"
                                                v-model="seat.manualPrice"
                                                placeholder="Preis eingeben"
                                                :disabled="
                                                    isFreeTicket ||
                                                    isBlockTicket
                                                "
                                                :value="
                                                    isFreeTicket
                                                        ? '€0,00'
                                                        : isBlockTicket
                                                        ? '-'
                                                        : seat.manualPrice
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button
                                    @click="removeSeat(seat)"
                                    class="delete-button"
                                    style=""
                                ></button>
                            </div>
                            <hr />
                        </li>
                    </ul>
                    <div v-if="selectedSeats.length > 0">
                        <button
                            @click="toggleForm"
                            class="btn btn-secondary mb-2"
                        >
                            {{ showForm ? "Felder Verstecken" : "Mehr Infos" }}
                        </button>
                        <div v-if="showForm">
                            <div class="form-group">
                                <label for="firstName">Vorname</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="first_name"
                                    v-model="first_name"
                                    placeholder="Vorname"
                                />
                            </div>
                            <div class="form-group">
                                <label for="lastName">Nachname</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="last_name"
                                    v-model="last_name"
                                    placeholder="Nachname"
                                />
                            </div>
                            <div class="form-group">
                                <label for="address">Adresse</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="address"
                                    v-model="address"
                                    placeholder="Adresse"
                                />
                            </div>
                            <div class="form-group">
                                <label for="zip_code">Postleitzahl</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="zip_code"
                                    v-model="zip_code"
                                    placeholder="Postleitzahl"
                                />
                            </div>
                            <div class="form-group">
                                <label for="city">Stadt</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="city"
                                    v-model="city"
                                    placeholder="Stadt"
                                />
                            </div>
                            <div class="form-group">
                                <label for="email">E-mail Adresse</label>
                                <input
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    v-model="email"
                                    placeholder="E-mail Adresse"
                                />
                            </div>
                            <div class="form-group">
                                <label for="phone">Telefon</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="phone"
                                    v-model="phone"
                                    placeholder="Telefon"
                                />
                            </div>
                            <div class="form-group">
                                <label for="notes">Notiz</label>
                                <textarea
                                    class="form-control"
                                    id="notes"
                                    name="notes"
                                    v-model="notes"
                                    placeholder="Notiz"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="errorMsg"
                        class="text-danger"
                        style="text-align: center; padding: 10px 0 5px 0"
                    >
                        {{ errorMsg }}
                    </div>
                    <button
                        @click.prevent="proceedToCheckout"
                        :disabled="cart.length === 0"
                        type="button"
                        class="btn btn-orange checkout-btn w-100 mt-2"
                    >
                        <i class="fas fa-money-bill mr-2"></i
                        >{{ proceedToCheckoutTxt }}
                    </button>
                </div>
                <div class="color_info">
                    <div class="color_info_item">
                        <div class="sample_box booked"></div>
                        Sold
                    </div>
                    <div class="color_info_item">
                        <div class="sample_box" style="background: #fff"></div>
                        Verfügbar
                    </div>
                    <div class="color_info_item">
                        <div class="sample_box assigned"></div>
                        Blockiert - vergeben
                    </div>
                    <div class="color_info_item">
                        <div class="sample_box unassigned"></div>
                        Blockiert - nicht vergeben
                    </div>
                    <div class="color_info_item">
                        <div class="sample_box hidden"></div>
                        Versteckt
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.color_info_item .sample_box {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    display: inline-block;
    margin-right: 5px;
}

.seat.selected {
    background-color: #4caf50;
    color: white;
}

.seat.booked,
.sample_box.booked {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}

.seat.assigned,
.sample_box.assigned {
    background-color: rgb(0, 96, 198);
    color: white;
    cursor: not-allowed;
}

.seat.unassigned,
.sample_box.unassigned {
    background-color: red;
    color: white;
    cursor: not-allowed;
}

.seat.hidden,
.sample_box.hidden {
    background-color: #262626;
    color: white;
    cursor: not-allowed;
}
</style>
