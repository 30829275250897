import "./bootstrap";
import { createApp } from "vue";
import SubscriptionEvents from "./components/admin/SubscriptionEvents";
import SeatPlan from "./components/admin/SeatPlan.vue";
import SeatPlanBook from "./components/admin/SeatPlanBook.vue";
import SeatPlanSite from "./components/site/SeatPlan.vue";
import NoSeatPlan from "./components/site/NoSeatPlan.vue";
import SeatPlanCanvas from "./components/site/SeatPlanCanvas.vue";
import OrderSummary from "./components/site/OrderSummary.vue";
import Subscription from "./components/site/Subscription.vue";
import AdminSeatMapCanvas from "./components/admin/AdminSeatMapCanvas.vue";
import AdminNoSeatPlan from "./components/admin/NoSeatPlan.vue";
import SeatMap from "./components/SeatMap.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Initialize Facebook Pixel
function initFacebookPixel() {
    if (window.fbq) return;
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1013709546441662');
    fbq('track', 'PageView');
  }

  initFacebookPixel();

// Admin components
const adminApp = createApp({});
adminApp.component("subscription-events", SubscriptionEvents);
adminApp.component("seat-plan", SeatPlan);
adminApp.component("seat-plan-book", SeatPlanBook);
adminApp.component("admin-seat-map-canvas", AdminSeatMapCanvas);
adminApp.component("admin-no-seat-plan", AdminNoSeatPlan);
adminApp.mount("#app");

// Site components
const siteApp = createApp({});
siteApp.component("no-seat-plan", NoSeatPlan);
siteApp.component("seat-plan-canvas", SeatPlanCanvas);
siteApp.component("order-summary", OrderSummary);
// siteApp.component('seat-map', SeatMap);
siteApp.component("seat-plan", SeatPlanSite);
siteApp.component("subscription", Subscription);
siteApp.mount("#site-app");

gsap.registerPlugin(ScrollTrigger);
// Register the new animation type with delay
gsap.utils.toArray(".animate").forEach((element, index) => {
    const delay = index * 0.2;
    gsap.from(element, {
        scrollTrigger: {
            trigger: element,
            start: "top 80%",
        },
        opacity: 0,
        duration: 1,
        delay: delay,
    });
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
            start: "top 80%",
        },
        opacity: 1,
        duration: 1,
        delay: delay,
    });
});
// Register the new animation type with delay for .fadein elements
gsap.utils.toArray(".fadein").forEach((element, index) => {
    const delay = index * 0.2;
    gsap.from(element, {
        scrollTrigger: {
            trigger: element,
            start: "top 80%",
        },
        opacity: 0,
        duration: 1,
        delay: delay,
    });
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
            start: "top 80%",
        },
        opacity: 1,
        duration: 1,
        delay: delay,
    });
});
