<template>
    <!-- Prices -->
    <div class="row mt-4">
        <div class="col-md-12 mb-4">
            <h3 class="settings-title">Sitzplan</h3>
            <!-- First Row - Seating Options -->
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group">
                        <span v-if="hasBoughtTickets" class="float-right text-muted">Preis kann nicht mehr verändert werden</span>
                        <div class="form-check">
                            <input v-model="seatType" class="form-check-input" type="radio" name="seat_type"
                                id="seat_plan" value="seat_plan" :disabled="disabled || hasBoughtTickets">
                            <label class="form-check-label" for="seatplan">
                                Sitzplan
                            </label>
                        </div>
                        <div class="form-check">
                            <input v-model="seatType" class="form-check-input" type="radio" name="seat_type"
                                id="seatplan_canvas" value="seatplan_canvas"
                                :disabled="disabled || hasBoughtTickets">
                            <label class="form-check-label" for="seatplan_canvas">
                                Sitzplan Canvas
                            </label>
                        </div>
                        <div class="form-check">
                            <input v-model="seatType" class="form-check-input" type="radio" name="seat_type"
                                id="no_seat_plan" value="no_seat_plan" :disabled="disabled || hasBoughtTickets">
                            <label class="form-check-label" for="no_seatplan">
                                Freie SItzplatzwahl
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-5">
                    <div v-if="seatType === 'seat_plan'" class="form-group">
                        <label for="seating">Sitzplan wählen</label>
                        <span v-if="hasBoughtTickets" class="float-right text-muted">Sitzplan kann nicht mehr verändert werden</span>
                        <select class="form-control" id="seating" v-model="selectedSeatPlanId"
                            @change="handleActivePlanChange" :disabled="disabled || hasBoughtTickets">
                            <option v-for="seatPlan in defaultSeatPlans" :key="seatPlan.id" :value="seatPlan.id"
                                :selected="selectedSeatPlanId === seatPlan.id">{{ seatPlan.name }} ({{ seatPlan.places
                                }} seats)</option>
                        </select>
                    </div>
                </div>
            </div>
            <input name="categories_json" :value="JSON.stringify(activeSeatPlan)" type="hidden" />
            <!-- <input name="svg_map_file" :value="svgContent" type="file"/> -->

            <!-- Second Row - Pricing Table -->
            <div v-if="seatType === 'seat_plan'" class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Kategorie</th>
                                <th>Beschreibung</th>
                                <th>Sitzplätze</th>
                                <th>Preis (€)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="category in activeSeatPlan.seat_plan_categories" :key="category.id">
                                <td><input type="text" class="form-control" v-model="category.name"
                                        :disabled="disabled"></td>
                                <td><input type="text" class="form-control" v-model="category.description"
                                        placeholder="Enter description text" :disabled="disabled"></td>
                                <td>{{ category.places }}</td>
                                <td><input type="number" class="form-control" v-model="category.price"
                                        :disabled="disabled" min="0.1" step="0.1"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="seatType === 'seatplan_canvas'" class="row">
                <div class="col-md-12">

                    <div class="form-group">
                        <label class="d-flex align-items-center p-3 mb-3 border">
                            <button @click.prevent="triggerFileInput" class="btn btn-primary mr-3">Choose File</button>
                            <span v-if="!svgFileName" class="flex-grow-1">No file chosen</span>
                            <span v-if="svgFileName" class="flex-grow-1">{{ svgFileName }}</span>
                            <input type="file" @change="handleFileUpload" accept=".svg" name="svg_map_file" ref="fileInput" class="d-none">
                        </label>
                    </div>


                    <div v-if="activeSeatPlan && Object.keys(activeSeatPlan).length > 0">
                        <table class="table">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Key</th>
                                <th>Description</th>
                                <th>Places</th>
                                <th>Price (€)</th>
                                <!-- <th>Actions</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="category in activeSeatPlan.seat_plan_categories" :key="category.id">
                                <td><input type="text" class="form-control" v-model="category.name"
                                        :disabled="disabled"></td>
                                <td><input type="text" class="form-control" v-model="category.category_key"
                                            :disabled="true"></td>
                                <td><input type="text" class="form-control" v-model="category.description"
                                        placeholder="Enter description text" :disabled="disabled"></td>
                                <td v-if="hasBoughtTickets">{{ category.places }}</td>
                                <td v-else><input type="number" class="form-control" v-model="category.places"
                                        :disabled="true" min="1" step="1"></td>
                                <td><input type="number" class="form-control" v-model="category.price"
                                        :disabled="disabled" min="0.1" step="0.1"></td>
                                <!-- <td>
                                    <a @click="handleDeleteCategory(category.id)"
                                        class="btn btn-danger mx-2 delete-record">
                                        <i class="fas fa-trash"></i>
                                    </a>
                                </td> -->
                            </tr>

                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-if="seatType === 'no_seat_plan'" class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Places</th>
                                <th>Price (€)</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="category in activeSeatPlan.seat_plan_categories" :key="category.id">
                                <td><input type="text" class="form-control" v-model="category.name"
                                        :disabled="disabled"></td>
                                <td><input type="text" class="form-control" v-model="category.description"
                                        placeholder="Enter description text" :disabled="disabled"></td>
                                <td v-if="hasBoughtTickets">{{ category.places }}</td>
                                <td v-else><input type="number" class="form-control" v-model="category.places"
                                        :disabled="disabled" min="1" step="1"></td>
                                <td><input type="number" class="form-control" v-model="category.price"
                                        :disabled="disabled" min="0.1" step="0.1"></td>
                                <td>
                                    <a @click="handleDeleteCategory(category.id)"
                                        class="btn btn-danger mx-2 delete-record">
                                        <i class="fas fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="5" style="text-align: right;">
                                    <a @click="handleAddCategory" class="mx-2" style="cursor: pointer;">
                                        Add Category
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        initSeatType: String,
        status: String,
        eventId: '',
        hasBoughtTickets: Boolean,
    },
    data() {
        return {
            seatType: this.initSeatType,
            disabled: false,
            defaultSeatPlans: [],
            customSeatPlan: {},
            activeSeatPlan: {},
            selectedSeatPlanId: null,
            svgContent: null,
            svgFileName: null
        }
    },
    mounted() {
        if (this.status === 'ended') {
            this.disabled = true
        }

        this.fetchSeatPlans()

        if (this.eventId) {
            this.fetchEventData(this.eventId)
        }
    },
    watch: {
        seatType: {
            handler(newVal, oldVal) {
                this.handleSeatTypeChange(newVal, oldVal);
            }
        },
    },
    methods: {
        handleSeatTypeChange(newVal, oldVal) {
            if (newVal === 'no_seat_plan') {
                this.activeSeatPlan = this.defaultSeatPlans[0]
                this.selectedSeatPlanId = this.activeSeatPlan.id
            } else if (newVal === 'seat_plan') {
                this.activeSeatPlan = this.customSeatPlan
            }
            else if(newVal == 'seatplan_canvas'){
                this.activeSeatPlan = this.customSeatPlan
            }
        },
        handleActivePlanChange() {
            const selectedId = this.selectedSeatPlanId;
            this.activeSeatPlan = this.defaultSeatPlans.find(seatPlan => seatPlan.id === selectedId)
        },
        handleDeleteCategory(id) {
            this.activeSeatPlan.seat_plan_categories = this.activeSeatPlan.seat_plan_categories.filter(seatCat => seatCat.id !== id)
        },
        handleAddCategory() {
            this.activeSeatPlan.seat_plan_categories.push({
                id: null,
                name: '',
                places: 0,
                price: 0,
                description: '',
                seat_plan_id: this.activeSeatPlan.id,
            })
        },
        fetchSeatPlans() {
            axios.post('/admin/event/getDefaultSeatPlans').then(response => {
                this.customSeatPlan = response.data.find(seatPlan => seatPlan.is_custom)
                this.defaultSeatPlans = response.data.filter(seatPlan => !seatPlan.is_custom)
                this.activeSeatPlan = this.customSeatPlan
            }).catch(error => {
                console.log(error)
            })
        },
        fetchEventData(id) {
            axios.post(`/admin/event/getData/${id}`).then(response => {
                console.log("response-->", response.data)

                this.activeSeatPlan = response.data.seat_plan
                this.activeSeatPlan.seat_plan_categories = response.data.seat_plan_categories
                this.seatType = response.data.seat_type

                this.svgFileName = response.data?.svg_map_file?.file_name

                if (response.data.seat_plan.is_custom === 0) {
                    this.selectedSeatPlanId = response.data.seat_plan.id
                }
            }).catch(error => {
                console.log(error)
            })
        },

            handleFileUpload(event) {
                const file = event.target.files[0];
                if (file && file.type === 'image/svg+xml') {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.svgContent = file;
                        this.svgFileName = file.name; // Store the file name
                        this.parseSvg(e.target.result);
                    };
                    reader.readAsText(file);
                } else {
                    alert('Please upload a valid SVG file.');
                    this.svgFileName = ''; // Clear the file name if invalid
                }
            },
            parseSvg(svgContent) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(svgContent, "image/svg+xml");

                const categories = doc.querySelectorAll("g[id^='cat-']");
                const seatPlanCategories = [];

                categories.forEach(category => {
                    const categoryKey = category.getAttribute('id').replace('cat-', '');
                    const seats = category.querySelectorAll("rect[id^='s']");
                    seatPlanCategories.push({
                        id: Math.random().toString(36).substr(2, 9), // Generate a random ID
                        name: '',
                        category_key: categoryKey,
                        places: seats.length,
                        price: 0,
                        description: '',
                        rows:0,
                        aisles_after:'',
                        seats: 0
                    });
                });

                this.activeSeatPlan.seat_plan_categories = seatPlanCategories;

                console.log("this.activeSeatPlan", this.activeSeatPlan)
            },
            triggerFileInput() {
                this.$refs.fileInput.click();
            }
    }
}
</script>

<style scoped></style>
