<script>
import axios from "axios";

export default {
    name: "SeatPlanBook",
    props: {
        eventId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            discounts: [],
            seatCategories: [],
            categories: [],
            bookings: [],
            boughtTickets: [],
            tooltipTickets: [],
            cart: [],
            errorMsg: "",
            event: {},
            first_name: "",
            last_name: "",
            address: "",
            zip_code: "",
            city: "",
            email: "",
            phone: "",
            notes: "",
            bookingType: "sell_ticket",
            seatType: "normal",
            proceedToCheckoutTxt: "Buchen",
            showForm: false,
            showSuccessModal: false,
            bookingId: null, // Add this line
        };
    },
    computed: {
        selectedSeats() {
            return this.cart;
        },
        isFreeTicket() {
            return this.bookingType === "free_ticket";
        },
        isBlockTicket() {
            return this.bookingType === "block_ticket";
        },
        statusControl() {
            if (this.isBlockTicket) this.seatType = this.seatType;
            else this.seatType = "normal";
        },
        showStatusSelect() {
            return this.bookingType === "block_ticket";
        },
    },
    watch: {
        bookingType(newVal) {
            if (newVal === "free_ticket") {
                this.cart.forEach((seat) => {
                    seat.manualPrice = 0;
                });
            }
        },
    },
    methods: {
        async fetchTooltipTickets(eventId) {
            const response = await axios.post(
                `/events/tooltipTickets/${this.eventId}`,
                {
                    id: this.eventId,
                }
            );
            if (response.status === 200) {
                this.tooltipTickets = response.data;
            } else {
                alert("Something went wrong");
            }
        },
        async fetchEvent(eventId) {
            try {
                const response = await axios.post(`/api/events/${eventId}`);
                this.processEventData(response.data);
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        },
        processEventData(data) {
            this.event = data;
            this.bookings = data.bookings;
            this.processDiscounts(data.discounts);
            this.boughtTickets = this.generateBoughtTickets(data.orders);
            this.seatCategories = this.generateSeatCategories(
                data.seat_plan_categories
            );
        },
        processDiscounts(discounts) {
            this.discounts = discounts.map((discount) => ({
                id: discount.id,
                name: discount.name,
                type: discount.type,
                fixed: discount.fixed,
                percentage: discount.percentage,
                description: discount.description,
            }));
        },
        generateBoughtTickets(orders) {
            const boughtTickets = [];
            orders.forEach((order) => {
                if (
                    order.order_status === "succeeded" ||
                    (order.order_status === "new" && order.wait_to_payment)
                ) {
                    const tickets = order.tickets
                        .filter((ticket) => {
                            return !ticket.is_cancelled && !ticket.is_refunded;
                        })
                        .map((ticket) => ({
                            ticketId: ticket.id,
                            categoryId: ticket.event_seat_plan_category_id,
                            categoryName: ticket.category_name,
                            isPaid: ticket.is_paid,
                            isFree: order.is_free,
                            row: ticket.row,
                            seat: ticket.seat,
                            seat_type: order.seat_type,
                            order_type: order.order_type,
                            payment_method: order.payment_method,
                        }));
                    boughtTickets.push(...tickets);
                }
            });
            return boughtTickets;
        },
        generateSeatCategories(categories) {
            return categories.map((category, categoryIndex) => {
                const aislesAfterArr = category.aisles_after
                    .split(",")
                    .map(Number);
                const discountPrices = this.calculateDiscountPrices(category);
                const regularPrice = {
                    name: "Regular",
                    price: category.price,
                    count: 0,
                };

                return {
                    id: category.id,
                    name: category.name,
                    price: category.price, // uses by default
                    prices: [regularPrice, ...discountPrices],
                    rows: Array(category.rows)
                        .fill()
                        .map((_, rowIndex) => {
                            return this.generateRow(
                                category.seats,
                                aislesAfterArr,
                                rowIndex + 1,
                                categoryIndex + 1,
                                category.id,
                                category.name
                            );
                        }),
                };
            });
        },
        calculateDiscountPrices(category) {
            return this.discounts.map((discount) => {
                const price =
                    discount.type === "percentage"
                        ? category.price -
                          category.price * (discount.percentage / 100)
                        : category.price - discount.fixed;

                return {
                    discount_id: discount.id,
                    name: discount.name,
                    price: price,
                    count: 0,
                };
            });
        },
        generateRow(
            seatCount,
            aislesAfter,
            rowNumber,
            categoryIndex,
            categoryId,
            categoryName
        ) {
            let row = [];
            for (let i = 0; i < seatCount; i++) {
                if (aislesAfter.includes(i)) {
                    row.push({ aisle: true }); // Add an aisle
                    // if (aislesAfter.includes(i + 1)) {
                    //     row.push({ aisle: true }); // Add a second aisle if the next seat is also an aisle
                    //     i++; // Skip the next seat
                    // }
                }
                let seatNumber = i + 1;
                let isBooked = this.bookings.some((booking) => {
                    return (
                        booking.row === rowNumber &&
                        booking.seat === seatNumber &&
                        booking.event_seat_plan_category_id === categoryId
                    );
                });

                let seatType = this.boughtTickets.find((ticket) => {
                    return (
                        ticket.row === rowNumber &&
                        ticket.seat === seatNumber &&
                        ticket.categoryId === categoryId
                    );
                })?.seat_type;

                let orderType = this.boughtTickets.find((ticket) => {
                    return (
                        ticket.row === rowNumber &&
                        ticket.seat === seatNumber &&
                        ticket.categoryId === categoryId
                    );
                })?.order_type;

                let paymentMethod = this.boughtTickets.find((ticket) => {
                    return (
                        ticket.row === rowNumber &&
                        ticket.seat === seatNumber &&
                        ticket.categoryId === categoryId
                    );
                })?.payment_method;

                let isBought = this.boughtTickets.some((ticket) => {
                    return (
                        ticket.row === rowNumber &&
                        ticket.seat === seatNumber &&
                        ticket.categoryId === categoryId
                    );
                });

                let seat = {
                    ticket_id: this.boughtTickets.find((ticket) => {
                        return (
                            ticket.row === rowNumber &&
                            ticket.seat === seatNumber &&
                            ticket.categoryId === categoryId
                        );
                    })?.ticketId,
                    selected: false,
                    is_paid: isBought
                        ? this.boughtTickets.find((ticket) => {
                              return (
                                  ticket.row === rowNumber &&
                                  ticket.seat === seatNumber &&
                                  ticket.categoryId === categoryId
                              );
                          }).isPaid
                        : false,
                    is_free: isBought
                        ? this.boughtTickets.find((ticket) => {
                              return (
                                  ticket.row === rowNumber &&
                                  ticket.seat === seatNumber &&
                                  ticket.categoryId === categoryId
                              );
                          }).isFree
                        : false,
                    booked: isBooked || isBought || false,
                    assigned: seatType === "assigned",
                    unassigned: seatType === "unassigned",
                    hidden: seatType === "hidden",
                    number: rowNumber,
                    seatNumber,
                    categoryIndex,
                    categoryId,
                    categoryName,
                    orderType,
                    paymentMethod,
                    tooltip:
                        this.tooltipTickets?.[categoryIndex - 1]?.[
                            rowNumber - 1
                        ]?.[seatNumber - 1] ?? null,
                };
                row.push(seat);
            }
            return row;
        },
        selectSeat(categoryIndex, rowIndex, seatIndex) {
            const category = this.seatCategories[categoryIndex];
            let seat = category.rows[rowIndex][seatIndex];
            seat.selected = !seat.selected;
            if (seat.selected) {
                seat.manualPrice = category.price;
                this.cart.push({
                    ...seat,
                    price: category.price,
                    prices: category.prices,
                });
            } else {
                let index = this.cart.findIndex(
                    (cartSeat) =>
                        cartSeat.number === seat.number &&
                        cartSeat.rowNumber === seat.rowNumber &&
                        cartSeat.categoryIndex === seat.categoryIndex
                );
                if (index !== -1) {
                    this.cart.splice(index, 1);
                }
            }
        },
        removeSeat(seat) {
            let index = this.cart.findIndex(
                (cartSeat) =>
                    cartSeat.number === seat.number &&
                    cartSeat.rowNumber === seat.rowNumber &&
                    cartSeat.categoryIndex === seat.categoryIndex
            );
            if (index !== -1) {
                this.cart.splice(index, 1);
            }
            const category = this.seatCategories.find(
                (el) => el.id === seat.categoryId
            );
            const row = category.rows.find((row) =>
                row.some((el) => el.number === seat.number)
            );
            const rowSeat = row.find((el) => el.seatNumber === seat.seatNumber);
            if (rowSeat) {
                rowSeat.selected = false;
            }
        },
        proceedToCheckout() {
            this.errorMsg = null;
            if (!this.cart.length) {
                this.errorMsg = "Cart is empty";
                return;
            }
            const tickets = this.filterTickets();
            const total = this.calculateTotal(tickets);
            this.bookTickets(tickets, total);
        },
        filterTickets() {
            return this.cart.flatMap((category) => {
                return {
                    event_id: this.event.id,
                    count: 1,
                    name: "Regular",
                    price: category.price,
                    manualPrice: this.formatPrice(category.manualPrice),
                    prices: category.prices, // uses when there are some discounts
                    row: category.number,
                    seat: category.seatNumber,
                    total: this.formatPrice(category.manualPrice),
                    categoryId: category.categoryId,
                    categoryName: category.categoryName,
                    eventName: this.event.name,
                    eventDate: this.formatDate(this.event.start_date),
                    eventTime: this.event.carbon_start_time,
                    eventLocation: `${this.event.venue.city}, ${this.event.venue.country}`,
                };
            });
        },
        calculateTotal(tickets) {
            const amount = tickets.reduce(function (total, item) {
                return total + parseFloat(item.manualPrice);
            }, 0);
            return amount.toFixed(2);
        },
        async bookTickets(tickets, total) {
            try {
                const response = await axios.post(
                    `/events/book/${this.eventId}`,
                    {
                        id: this.eventId,
                        bookingType: this.bookingType,
                        tickets_data: {
                            tickets: tickets,
                            amount: total,
                        },
                        customer_data: {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            address: this.address,
                            zip_code: this.zip_code,
                            city: this.city,
                            email: this.email,
                            phone: this.phone,
                        },
                        notes: this.notes,
                        seatType: this.seatType,
                    }
                );

                if (response.status === 200) {
                    this.showSuccessModal = true; // Show the success modal
                    this.bookingId = response.data.bookingId; // Store the booking ID
                } else {
                    alert("Something went wrong");
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    this.errorMsg = error.response.data.message;
                    console.error("Booking error:", error.response.data.errors);
                    this.showToastMessage(
                        "Booking error:" + error.response.data.errors
                    );
                } else {
                    this.errorMsg =
                        "An unexpected error occurred. Please try again.";
                    console.error(
                        "There was an error processing the checkout:",
                        error
                    );
                    this.showToastMessage(
                        "Error booking the tickets. Please try again."
                    );
                }
            }
        },
        handleChangePrice(index) {
            const seat = this.selectedSeats[index];
            seat.manualPrice = seat.price;
        },
        handleBookingTypeChange() {
            if (this.bookingType === "block_ticket") {
                this.proceedToCheckoutTxt = "Blockieren";
            } else {
                this.proceedToCheckoutTxt = "Buchen";
            }
        },
        formatPrice(price) {
            return new Intl.NumberFormat("us-US", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(price);
        },
        formatDate(date) {
            if (!date) return "";
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return new Date(date).toLocaleDateString("de", options);
        },
        formatTime(time) {
            return time
                ? new Date(time).toLocaleTimeString("de", {
                      hour: "numeric",
                      minute: "numeric",
                  })
                : "";
        },
        toggleForm() {
            this.showForm = !this.showForm;
        },
        closeSuccessModal() {
            this.showSuccessModal = false;
            window.location.reload(); // Refresh the edit event page
        },
        fixFormatPrice(seat) {
            // Remove any non-numeric characters except for the dot
            seat.manualPrice = seat.manualPrice.replace(/[^0-9.]/g, "");

            // Ensure the price has at most two decimal places
            seat.manualPrice = seat.manualPrice.replace(/(\.\d{2})\d+/, "$1");

            // Format the price with commas for thousands
            /*seat.manualPrice = seat.manualPrice.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
            );*/

            // If there are multiple dots, keep only the first one
            if (seat.manualPrice.includes(".")) {
                let parts = seat.manualPrice.split(".");
                if (parts.length > 2) {
                    seat.manualPrice = parts[0] + "." + parts[1];
                }
            }

            // Parse the formatted price as a float
            if (
                seat.manualPrice === "" ||
                seat.manualPrice === "NaN" ||
                seat.manualPrice === "."
            ) {
                seat.manualPrice = 0;
            }
            seat.manualPrice = parseFloat(seat.manualPrice);
        },
        hideAndShow() {
            const selectedBox = document.querySelector(".selected-box");
            const slideSelectBox = document.getElementById("slideSelectBox");
            const selectedBoxWidth = selectedBox.offsetWidth;
            const slideSelectBoxWidth = slideSelectBox.offsetWidth;

            if (selectedBox.dataset.visibility === "show") {
                selectedBox.style.transform = `translate(${selectedBoxWidth}px, 0)`;
                selectedBox.dataset.visibility = "hide";
                slideSelectBox.innerHTML = '<i class="fas fa-arrow-left"></i>';
            } else {
                selectedBox.style.transform = "translate(0, 0)";
                selectedBox.dataset.visibility = "show";
                slideSelectBox.innerHTML = '<i class="fas fa-arrow-right"></i>';
            }
        },
        fullScreen(getEl) {
            if (getEl === undefined) return;

            window.scrollTo(0, 0);
            if (getEl.classList.contains("full-screen")) {
                getEl.classList.remove("full-screen");
                document.getElementById("myTabContent").style.width = "unset";
                document.getElementById("myTabContent").style.height = "unset";
                document.getElementById("myTabContent").style.position =
                    "relative";
                document.getElementById("myTabContent").style.top = "unset";
                document.getElementById("myTabContent").style.left = "unset";
                document.getElementById("myTabContent").style.zIndex = "unset";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.width = "unset";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.height = "unset";
                document.getElementById("fullScreen").innerHTML =
                    '<i class="fas fa-expand"></i>';
            } else {
                getEl.classList.add("full-screen");
                document.getElementById("myTabContent").style.width = "100%";
                document.getElementById("myTabContent").style.height = "100%";
                document.getElementById("myTabContent").style.position =
                    "absolute";
                document.getElementById("myTabContent").style.top = "0";
                document.getElementById("myTabContent").style.left = "0";
                document.getElementById("myTabContent").style.zIndex = "9999";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.width = "100%";
                document.querySelector(
                    "#myTabContent > #adminBookings"
                ).style.height = "100%";
                document.getElementById("fullScreen").innerHTML =
                    '<i class="fas fa-compress"></i>';
            }
        },
        loadDynamicStyles() {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = "/css/svgStyles.css";
            document.head.appendChild(link);
        },
        cancelTicket(ticketId) {
            const confirmed = confirm("Are you sure you want to cancel?");
            if (confirmed) {
                axios
                    .get(`/admin/orders/${ticketId}/cancel`)
                    .then((response) => {
                        if (response.status === 200) {
                            this.fetchEvent(this.eventId);
                            this.fetchTooltipTickets(this.eventId);
                        } else {
                            alert("Something went wrong");
                        }
                    })
                    .catch((error) => {
                        console.error("Error cancelling ticket:", error);
                    });
            }
        },
    },
    async created() {
        await this.fetchTooltipTickets(this.eventId);
        await this.fetchEvent(this.eventId);
        this.loadDynamicStyles();
    },
    mounted() {
        // Initialize Bootstrap tooltips
        $(function () {
            $(".show-tooltip").tooltip({
                delay: { show: 0, hide: 0 },
                html: true,
            });
        });
    },
    updated() {
        $(function () {
            $(".show-tooltip")
                .tooltip("dispose")
                .tooltip({
                    delay: { show: 0, hide: 0 },
                    html: true,
                });
        });
    },
};
</script>

<template>
    <div class="seatmap">
        <div
            v-for="(category, categoryIndex) in seatCategories"
            :key="categoryIndex"
            class="category"
        >
            <div
                v-if="categoryIndex === 0"
                class="bg-black text-white py-6 mb-3 category-name w-100 w-md-50 text-center"
            >
                <p>BÜHNE</p>
            </div>
            <div
                class="category-name px-3 py-2 mb-2"
                style="
                    background-color: #ffc107;
                    color: black;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 10px;
                "
            >
                {{ category.name }}
            </div>
            <div class="category-seats px-3">
                <div
                    v-for="(row, rowIndex) in category.rows"
                    :key="rowIndex"
                    class="row seat-overflow"
                >
                    <div
                        class="rowNumber"
                        style="width: 50px; height: 40px; line-height: 40px"
                    >
                        <span>{{ rowIndex + 1 }}</span>
                    </div>
                    <div
                        v-for="(seat, seatIndex) in row"
                        @click="
                            !seat.aisle &&
                                !seat.booked &&
                                selectSeat(categoryIndex, rowIndex, seatIndex)
                        "
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="seat.tooltip"
                        :key="seatIndex"
                        class="seat show-tooltip"
                        :class="{
                            aisle: seat.aisle,
                            selected: seat.selected,
                            booked:
                                !seat.assigned &&
                                !seat.unassigned &&
                                !seat.hidden &&
                                seat.booked,
                            assigned: seat.assigned,
                            unassigned: seat.unassigned,
                            hidden: seat.hidden,
                        }"
                    >
                        <span class="seatnumber">
                            {{ seat.seatNumber }}
                        </span>
                        <div v-if="seat.ticket_id" class="dropdown hidden">
                            <button
                                class="btn btn-light btn-sm dropdown-toggle"
                                type="button"
                                id="cancelDropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                            <div
                                class="dropdown-menu"
                                aria-labelledby="cancelDropdown"
                            >
                                <a
                                    v-if="
                                        seat.orderType == 'admin' &&
                                        seat.paymentMethod == 'Offline'
                                    "
                                    @click="cancelTicket(seat.ticket_id)"
                                    class="dropdown-item"
                                    type="button"
                                    >Delete ticket</a
                                >
                                <a v-else class="dropdown-item" type="button"
                                    >No action</a
                                >
                            </div>
                        </div>
                        <!--<span v-if="seat.selected"> ✓ </span>-->
                        <!--<span v-if="seat.booked"> x </span>-->
                        <!--<span v-else-if="!seat.aisle"> </span>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="box-controls">
            <button
                class="btn-orange px-3 rounded"
                @click="fullScreen($event.target)"
                id="fullScreen"
            >
                <i class="fas fa-expand"></i>
            </button>
            <button
                class="btn-orange px-3 rounded ml-2"
                @click="hideAndShow"
                id="slideSelectBox"
            >
                <i class="fas fa-arrow-right"></i>
            </button>
        </div>
        <div class="selected-box">
            <div class="selected-cats">
                <h2 class="cart-heading">Kategorien</h2>
                <hr />
                <div class="d-flex flex-col pt-2 gap-2">
                    <div
                        v-for="(category, categoryIndex) in seatCategories"
                        :key="categoryIndex"
                        class="category d-flex flex-row gap-2 justify-content-between"
                    >
                        <div class="">{{ category.name }}</div>
                        <div class="">€{{ formatPrice(category.price) }}</div>
                    </div>
                </div>
            </div>
            <div class="selected-seats">
                <h2 class="cart-heading">Warenkorb</h2>
                <hr />
                <div class="form-group pt-2">
                    <label for="bookingType">Buchungsart wählen:</label>
                    <select
                        v-model="bookingType"
                        class="form-control"
                        @change="handleBookingTypeChange, statusControl"
                    >
                        <option value="sell_ticket">Ticket verkaufen</option>
                        <option value="free_ticket">Kostenloses Ticket</option>
                        <option value="block_ticket">
                            Sitzplatz blockieren
                        </option>
                        <option value="box_office_ticket">Abendkasse</option>
                    </select>
                </div>
                <div class="form-group pt-2" v-if="showStatusSelect">
                    <label for="status">Status wählen:</label>
                    <select v-model="seatType" class="form-control">
                        <option value="normal">Normal</option>
                        <option value="assigned">Zugewiesen</option>
                        <option value="unassigned">Nicht zugewiesen</option>
                        <option value="hidden">Versteckt</option>
                    </select>
                </div>
                <hr />
                <ul class="seat-list pb-2">
                    <li v-for="(seat, index) in selectedSeats" :key="index">
                        <div
                            class="d-flex col px-0 py-2 gap-2 justify-content-between"
                        >
                            <div>
                                <p>1x {{ seat.categoryName }}</p>
                                <div class="d-flex flex-col px-0">
                                    <small
                                        >Reihe: {{ seat.number }}, Platz:
                                        {{ seat.seatNumber }}</small
                                    >
                                    <small v-if="seat.prices.length === 1"
                                        ><strong
                                            >Preis:
                                            {{
                                                formatPrice(seat.price)
                                            }}</strong
                                        ></small
                                    >
                                    <div v-if="seat.prices.length > 1">
                                        <label>Preis: </label>
                                        <select
                                            v-model="seat.price"
                                            class="form-control"
                                            @change="handleChangePrice(index)"
                                            :disabled="
                                                bookingType !== 'sell_ticket' &&
                                                bookingType !==
                                                    'box_office_ticket'
                                            "
                                        >
                                            <option
                                                v-for="(
                                                    price, idx
                                                ) in seat.prices"
                                                :key="idx"
                                                :value="price.price"
                                            >
                                                €{{ formatPrice(price.price) }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="manualPrice"
                                            >Preis eingeben</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="manualPrice"
                                            @input="fixFormatPrice(seat)"
                                            v-model="seat.manualPrice"
                                            placeholder="Preis eingeben"
                                            :disabled="
                                                bookingType !== 'sell_ticket' &&
                                                bookingType !=
                                                    'box_office_ticket'
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                @click="removeSeat(seat)"
                                class="delete-button"
                                style=""
                            ></button>
                        </div>
                        <hr />
                    </li>
                </ul>
                <div v-if="selectedSeats.length > 0">
                    <button @click="toggleForm" class="btn btn-secondary mb-2">
                        {{ showForm ? "Felder Verstecken" : "Mehr Infos" }}
                    </button>
                    <div v-if="showForm">
                        <div class="form-group">
                            <label for="firstName">Vorname</label>
                            <input
                                type="text"
                                class="form-control"
                                name="first_name"
                                v-model="first_name"
                                placeholder="Vorname"
                            />
                        </div>
                        <div class="form-group">
                            <label for="lastName">Nachname</label>
                            <input
                                type="text"
                                class="form-control"
                                name="last_name"
                                v-model="last_name"
                                placeholder="Nachname"
                            />
                        </div>
                        <div class="form-group">
                            <label for="address">Adresse</label>
                            <input
                                type="text"
                                class="form-control"
                                name="address"
                                v-model="address"
                                placeholder="Adresse"
                            />
                        </div>
                        <div class="form-group">
                            <label for="zip_code">Postleitzahl</label>
                            <input
                                type="text"
                                class="form-control"
                                name="zip_code"
                                v-model="zip_code"
                                placeholder="Postleitzahl"
                            />
                        </div>
                        <div class="form-group">
                            <label for="city">Stadt</label>
                            <input
                                type="text"
                                class="form-control"
                                name="city"
                                v-model="city"
                                placeholder="Stadt"
                            />
                        </div>
                        <div class="form-group">
                            <label for="email">E-mail Adresse</label>
                            <input
                                type="email"
                                class="form-control"
                                name="email"
                                v-model="email"
                                placeholder="E-mail Adresse"
                            />
                        </div>
                        <div class="form-group">
                            <label for="phone">Telefon</label>
                            <input
                                type="text"
                                class="form-control"
                                name="phone"
                                v-model="phone"
                                placeholder="Telefon"
                            />
                        </div>
                        <div class="form-group">
                            <label for="notes">Notiz</label>
                            <textarea
                                class="form-control"
                                id="notes"
                                name="notes"
                                v-model="notes"
                                placeholder="Notiz"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div
                    v-if="errorMsg"
                    class="text-danger"
                    style="text-align: center; padding: 10px 0 5px 0"
                >
                    {{ errorMsg }}
                </div>
                <button
                    @click.prevent="proceedToCheckout"
                    :disabled="cart.length === 0"
                    type="button"
                    class="btn btn-orange checkout-btn w-100 mt-2"
                >
                    <i class="fas fa-money-bill mr-2"></i
                    >{{ proceedToCheckoutTxt }}
                </button>
            </div>
            <div class="color_info">
                <div class="color_info_item">
                    <div class="sample_box booked"></div>
                    Verkauft
                </div>
                <div class="color_info_item">
                    <div class="sample_box" style="background: #fff"></div>
                    Verfügbar
                </div>
                <div class="color_info_item">
                    <div class="sample_box assigned"></div>
                    Blockiert - zugewiesen
                </div>
                <div class="color_info_item">
                    <div class="sample_box unassigned"></div>
                    Blockiert - nicht zugewiesen
                </div>
                <div class="color_info_item">
                    <div class="sample_box hidden"></div>
                    Versteckt
                </div>
            </div>
        </div>
        <div
            v-if="showSuccessModal"
            class="modal"
            tabindex="-1"
            role="dialog"
            style="display: block"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Buchung Erfolgreich</h5>
                        <button
                            type="button"
                            class="close"
                            @click="closeSuccessModal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Ihre Buchung war erfolgreich!</p>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="closeSuccessModal"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cart-heading {
    text-align: center;
    margin-bottom: 10px !important;
    font-size: 18px;
    font-weight: 600;
}
.seatmap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}

.category-name {
    font-weight: bold;
    text-align: center;
}

.row {
    display: flex;
    justify-content: center;
}

.seat {
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}

.seat .cancel-ticket {
    display: none;
}

.seat:hover .cancel-ticket {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f00;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.seat:hover {
    background-color: #ddd;
}

.seat.aisle {
    background-color: unset;
    border: none;
    cursor: default;
}

.color_info_item .sample_box {
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    display: inline-block;
    margin-right: 5px;
}

.seat.selected {
    background-color: #4caf50;
    color: white;
}

.seat.booked,
.sample_box.booked {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}

.seat.assigned,
.sample_box.assigned {
    background-color: rgb(0, 96, 198);
    color: white;
    cursor: not-allowed;
}

.seat.unassigned,
.sample_box.unassigned {
    background-color: #cf2d2d;
    color: white;
    cursor: not-allowed;
}

.seat.hidden,
.sample_box.hidden {
    background-color: #262626;
    color: white;
    cursor: not-allowed;
}

.selected-seats {
    position: relative;
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-height: 550px;
    overflow: scroll;
}

.selected-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    top: 0;
    right: 10px;
    position: absolute;
    margin-top: 56px;
    transition: all 0.3s ease;
    height: calc(100% - 56px);
    padding: 10px 0;
}

.selected-cats {
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.selected-seats h2 {
    text-align: center;
    margin-bottom: 20px;
}

.delete-button {
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #d9d9d9;
    border-radius: 10px;
    color: black;
}

.delete-button:before {
    content: "\2715";
    font-size: 16px;
}
.checkout-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.btn-orange {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(250, 180, 0, 0.3);
    height: calc(2.75rem + 2px);
    font-weight: 600;
    color: white;
}

.seat {
    position: relative;
}

.seat .dropdown.hidden {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}

.seat:hover > .dropdown.hidden {
    display: block;
}

@media (max-width: 480px) {
    .selected-seats {
        position: relative;
        bottom: 0;
        top: unset;
        right: 0;
        width: 100%;
        max-height: 330px;
        background-color: white;
        border: 1px solid #ddd;
        padding: 20px;
        z-index: 1;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .seat-list {
        overflow: scroll;
        max-height: 150px;
    }

    .seat-overflow {
        flex-wrap: nowrap !important;
        overflow: visible;
    }
    .seatmap {
        overflow: scroll;
        align-items: baseline;
    }
    .selected-box {
        position: relative;
        right: 0;
        margin-top: 0px;
    }
}
</style>
