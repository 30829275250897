<script>
import axios from 'axios';
import Cookies from "js-cookie";
import { h } from 'vue';

export default {
    name: "SeatPlan",
    props: {
        eventId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            discounts: [],
            seatCategories: [],
            categories: [],
            bookings: [],
            boughtTickets: [],
            cart: [],
            errorMsg: '',
            event: {},
            svgScale: 0.75,
            isDragging: false,
            startX: 0,
            startY: 0,
            dragOffsetX: 0,
            dragOffsetY: 0,
            svgURL: null,
            loading: true,
            visible: false,
            isMobile: false,
            seatmapClass: "seatmap overflow-hidden absolute",
        };
    },
    computed: {
        selectedSeats() {
            return this.cart;
        }
    },
    created() {
        this.fetchEvent(this.eventId);
    },
    mounted() {

        this.checkDeviceAndModifyState();

        this.$nextTick(() => {
            const rects = document.querySelectorAll('rect');
            rects.forEach(rect => {
                rect.addEventListener('click', this.toggleSeatSelection);
            });
        });
        this.$nextTick(() => {
            // Existing mounted logic...
            window.addEventListener('mouseup', this.endDrag);
            window.addEventListener('touchend', this.endDrag);
        });


    },
    beforeDestroy() {
        window.removeEventListener('mouseup', this.endDrag);
        window.removeEventListener('touchend', this.endDrag);
    },
    watch: {
        seatCategories(newVal, oldVal) {
            if (newVal) {
                // Trigger the function when svgURL state has data
                // this.markAlreadyBookedSeatsDisabled();
            }
        },
    },
    methods: {
        checkDeviceAndModifyState() {
        // Check if the device is mobile-sized (width less than 768px, for example)
        this.isMobile = window.innerWidth < 768;
        if (this.isMobile) {
            // Remove 'overflow-hidden' class
            this.seatmapClass = "seatmap absolute";

            // Update state
            //this.dragOffsetX = -100;

            const seatmapDiv = document.getElementById('seatmapDiv');
            console.log("seatmapDiv", seatmapDiv)
            if (seatmapDiv) {
                const scrollX = (seatmapDiv.scrollWidth - seatmapDiv.clientWidth) / 2;
                seatmapDiv.scrollLeft = scrollX;
            }

        }
        },
        async fetchEvent(eventId) {
            try {
                const response = await axios.post(`/api/events/${eventId}`);
                // Set the svg content from the api call in the vue state
                await this.setSvgContent(response.data)
                this.processEventData(response.data);

                // this.markAlreadyBookedSeatsDisabled();
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        },
        setSvgContent(data) {
            try {
                let {seatmap_path} = data
                // let svgUrlFromApi = svg_map_file?.original_url
                let svgUrlFromApi = seatmap_path
                this.svgURL = svgUrlFromApi
                this.fetchSvgContent(svgUrlFromApi)
            } catch (error) {
                console.error("Error in setting svg url", error);
            }
        },
        async fetchSvgContent(svgUrl) {
            try {


                // Extract the relative path
                let relativePath = new URL(svgUrl).pathname;

                const response = await fetch(relativePath);
                if (!response.ok) {
                    throw new Error('Failed to fetch SVG');
                }
                const svgContent = await response.text();

                this.renderSvgContent(svgContent);


            } catch (error) {
                console.error('Error fetching SVG:', error);
            }
        },

        markAlreadyBookedSeatsDisabled() {
            // setTimeout(() => {


            // Disable tickets which are already booked
            this.bookings.forEach((booking, currentIndex) => {
                let seatNunmber = booking.seat;
                let row = booking.row;
                let category_key = this.findNameById(this.seatCategories, booking.event_seat_plan_category_id);
                let categoryWithPrefix = `cat-${category_key}`;

                let parentDiv = document.getElementById(categoryWithPrefix)
                // Select the <g> element containing id attribute with "row_1"
                let groupElement = parentDiv.querySelector(`g[id*="r${row}"]`);

                let rectGroupElement = groupElement.querySelector(`rect[id*="s${seatNunmber}_"]`);
                if (rectGroupElement) {
                    // Remove the 'selected' class from rectGroupElement
                    // rectGroupElement.classList.add('selected');
                    rectGroupElement.classList.add('booked');
                }
            })

            // Disable the already bought tickets
            this.boughtTickets.forEach((boughtTicket, currentIndex) => {
                let seatNunmber = boughtTicket.seat;
                let row = boughtTicket.row;
                let category_key = boughtTicket.category_key;
                let categoryWithPrefix = `cat-${category_key}`;

                let parentDiv = document.getElementById(categoryWithPrefix)
                // Select the <g> element containing id attribute with "row_1"
                let groupElement = parentDiv.querySelector(`g[id*="r${row}"]`);

                let rectGroupElement = groupElement.querySelector(`rect[id*="s${seatNunmber}_"]`);
                if (rectGroupElement) {
                    // Remove the 'selected' class from rectGroupElement
                    // rectGroupElement.classList.add('selected');
                    rectGroupElement.classList.add('booked');
                }
            })

            // }, 2000);

        },
        // Function to find the name based on id
        findNameById(data, id) {
            let foundItem = data.find(item => item.id === id);
            let keyToReturn = foundItem ? foundItem.category_key : null;
            return keyToReturn;
        },
        renderSvgContent(svgContent) {
            // Inject SVG content into the div
            const svgMapDiv = document.getElementById('svgMapId');

            svgMapDiv.innerHTML = this.stripSvgTag(svgContent);

            // Get width, height and viewBox of svg uploaded
            let {width, height, viewBox} = this.extractSVGAttributes(svgContent);

            // Add class and inline styles to the SVG
            const svgElement = svgMapDiv; // Assuming the top-level element is <svg>

            if (svgElement) {
                //if (width) svgElement.setAttribute('width', width);
                //if (height) svgElement.setAttribute('height', height);
                if (viewBox) svgElement.setAttribute('viewBox', viewBox);
            }

            // Add event listener for each rectangle
            const rects = document.querySelectorAll('rect');
            rects.forEach(rect => {
                rect.addEventListener('click', this.toggleSeatSelection);
            });

            this.loadDynamicStyles();

            this.loading = false;
            this.visible = true;
            this.markAlreadyBookedSeatsDisabled();

        },

        extractSVGAttributes(svgContent) {
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");
            const svgElement = svgDoc.querySelector("svg");

            if (svgElement) {
                // Get the width and height
                const width = svgElement.getAttribute('width');
                const height = svgElement.getAttribute('height');

                // Get the viewBox attribute
                const viewBox = svgElement.getAttribute('viewBox');

                // console.log('Width:', width);
                // console.log('Height:', height);
                // console.log('ViewBox:', viewBox);

                // Append the SVG to the DOM
                return {
                    width,
                    height,
                    viewBox
                }
            } else {
                return null;
            }
        },
        stripSvgTag(svgContent) {
            // Create a temporary DOM element to parse the SVG content
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = svgContent;

            // Get the <svg> element
            const svgElement = tempDiv.querySelector('svg');

            // If an <svg> element exists, return its inner HTML
            if (svgElement) {
                return svgElement.innerHTML;
            }

            // If no <svg> element is found, return the original content
            return svgContent;
        },
        getGrandParentId(element) {
            if (!element) return null;

            const parent = element.parentElement;
            if (!parent) return null;

            const grandParent = parent.parentElement;
            if (!grandParent) return null;

            const greatGrandParent = grandParent.parentElement;
            if (!greatGrandParent) return null;

            return greatGrandParent.id || null;
        },
        getIds(element) {
            const ids = {
                parentId: null,
                grandParentId: null,
                greatGrandParentId: null
            };

            if (!element) return ids;

            const parent = element.parentElement;
            if (parent) {
                ids.parentId = parent.id || null;

                const grandParent = parent.parentElement;
                if (grandParent) {
                    ids.grandParentId = grandParent.id || null;

                    const greatGrandParent = grandParent.parentElement;
                    if (greatGrandParent) {
                        ids.greatGrandParentId = greatGrandParent.id || null;
                    }
                }
            }

            return ids;
        },

        toggleSeatSelection(event) {
            const rect = event.target;

            let idOfSelectedRect = rect.id;

            // Extract the number before the underscore
            let numberBeforeUnderscore = idOfSelectedRect.split('_')[0].substr(1);

            let seatNumber = numberBeforeUnderscore;

            // Get IDs of parent, grandparent, and great-grandparent
            const ids = this.getIds(rect);

            // Log the IDs for testing or further processing
            // console.log("Parent ID:", ids.parentId);
            // console.log("Grandparent ID:", ids.grandParentId);
            // console.log("Great-grandparent ID:", ids.greatGrandParentId);


            // Example string
            const grandParentId = ids.grandParentId;

            // Extract the number before '_'
            const parts = grandParentId.split('_');
            const rowNumber = parts[0].substring(1); // Remove the 'r' prefix

            let categoryName = ids.greatGrandParentId;
            let correspondingCategory = this.findCategory(categoryName);


            if (rect.tagName !== 'rect') {
                return; // Ensure only <rect> elements are targeted
            }

            let seat = {};

            if (rect.classList.contains('selected')) {
                rect.classList.remove('selected');

                seat = {
                    "booked": true,
                    "categoryId": correspondingCategory.id,
                    "categoryIndex": 1,
                    "categoryName": correspondingCategory.name,
                    "number": parseInt(rowNumber),
                    "seatNumber": parseInt(seatNumber),
                    "selected": true,
                    "category_key": correspondingCategory.category_key,
                }


                let index = this.cart.findIndex(cartSeat => {
                    return cartSeat.number === seat.number && cartSeat.seatNumber === seat.seatNumber && cartSeat.categoryIndex === seat.categoryIndex
                });

                if (index !== -1) {
                    this.cart.splice(index, 1);
                }

            } else {

                seat = {
                    "booked": true,
                    "categoryId": correspondingCategory.id,
                    "categoryIndex": 1,
                    "categoryName": correspondingCategory.name,
                    "number": parseInt(rowNumber),
                    "seatNumber": parseInt(seatNumber),
                    "selected": true,
                    "category_key": correspondingCategory.category_key,
                }

                this.cart.push({
                    ...seat,
                    price: correspondingCategory.price,
                    prices: correspondingCategory.prices
                });

                rect.classList.add('selected');
            }
        },
        findCategory(categoryName) {
            const nameToFind = categoryName.replace('cat-', '');
            return this.seatCategories.find(category => category.category_key === nameToFind);
        },

        zoomIn() {
            if (window.innerWidth <= 480)
                this.svgScale = Math.min(this.svgScale + 0.1, 4); // Max scale 4x for mobile
            else
                this.svgScale = Math.min(this.svgScale + 0.1, 2); // Max scale 2x
        },
        zoomOut() {
            this.svgScale = Math.max(this.svgScale - 0.1, 0.5); // Min scale 0.5x
        },
        oneToOne() {
            //const svg = this.$el.querySelector('svg.draggable');
            //const containerWidth = svg.parentElement.clientWidth;
            //const svgWidth = svg.viewBox.baseVal.width;
            //this.svgScale = containerWidth / svgWidth - 0.025;
            this.svgScale = 0.9;
            this.dragOffsetX = 0;
            this.dragOffsetY = 0;
            this.updateSVGPosition();

            if (window.innerWidth <= 480) return;
            this.hideAndShow();
        },
        hideAndShow() {
            const selectedBox = document.querySelector('.selected-box');
            const slideSelectBox = document.getElementById('slideSelectBox');
            const selectedBoxWidth = selectedBox.offsetWidth;
            const slideSelectBoxWidth = slideSelectBox.offsetWidth;
            
            if (selectedBox.dataset.visibility === 'show') {
                selectedBox.style.transform = `translate(${selectedBoxWidth}px, 0)`;
                selectedBox.dataset.visibility = 'hide';
                slideSelectBox.innerHTML = '<i class="fas fa-arrow-left"></i>';
            } else {
                selectedBox.style.transform = 'translate(0, 0)';
                selectedBox.dataset.visibility = 'show';
                slideSelectBox.innerHTML = '<i class="fas fa-arrow-right"></i>';
            }
        },
        startDrag(event) {
            this.isDragging = true;
            if (event.type === 'mousedown') {
            this.startX = event.clientX - this.dragOffsetX;
            this.startY = event.clientY - this.dragOffsetY;
            } else if (event.type === 'touchstart') {
            document.body.style.overflow = 'hidden';
            if (event.touches.length < 2) {
                this.startX = event.touches[0].clientX - this.dragOffsetX;
                this.startY = event.touches[0].clientY - this.dragOffsetY;
            }
            else {
                event.preventDefault();
                this.startX = Math.abs(event.touches[0].clientX - event.touches[1].clientX) / 2;
                this.startY = Math.abs(event.touches[0].clientY - event.touches[1].clientY) / 2;
                this.startScale = this.svgScale;
                this.startDistance = Math.hypot(
                    event.touches[0].clientX - event.touches[1].clientX,
                    event.touches[0].clientY - event.touches[1].clientY
                );
                this.startAngle = Math.atan2(
                    event.touches[0].clientY - event.touches[1].clientY,
                    event.touches[0].clientX - event.touches[1].clientX
                );
                this.startAngle = (this.startAngle * 180) / Math.PI;

                this.isPinchZooming = true;
            }
            }
        },
        onDrag(event) {
            if (!this.isDragging) return;
            if (event.type === 'mousemove') {
            this.dragOffsetX = event.clientX - this.startX;
            this.dragOffsetY = event.clientY - this.startY;
            } else if (event.type === 'touchmove') {
                event.preventDefault();
                if (event.touches.length < 2) {
                    this.dragOffsetX = event.touches[0].clientX - this.startX;
                    this.dragOffsetY = event.touches[0].clientY - this.startY;
                } else {
                    const distance = Math.hypot(
                        event.touches[0].clientX - event.touches[1].clientX,
                        event.touches[0].clientY - event.touches[1].clientY
                    );
                    const scale = distance / this.startDistance;
                    this.svgScale = this.startScale * scale;
                    const angle = Math.atan2(
                        event.touches[0].clientY - event.touches[1].clientY,
                        event.touches[0].clientX - event.touches[1].clientX
                    );
                    this.startAngle = (angle * 180) / Math.PI;
                }
            }
            this.updateSVGPosition();
        },
        endDrag() {
            this.isDragging = false;
            document.body.style.overflow = 'auto';
            this.isPinchZooming = false;
            if (this.svgScale < 0.5 || this.svgScale > 4) {
                if (this.svgScale < 0.5)
                    this.svgScale = 0.5;
                else if (this.svgScale > 4)
                    this.svgScale = 4;
                this.updateSVGPosition();
            }
        },
        updateSVGPosition() {
            const svg = this.$el.querySelector('svg.draggable');
            svg.style.transform = `translate(${this.dragOffsetX}px, ${this.dragOffsetY}px) scale(${this.svgScale})`;
        },
        processEventData(data) {
            this.event = data;
            this.bookings = data.bookings;
            this.processDiscounts(data.discounts);
            this.seatCategories = this.generateSeatCategories(data.seat_plan_categories);
            this.boughtTickets = this.generateBoughtTickets(data.orders);

            console.log("boughtTickets--->", this.boughtTickets)

        },
        processDiscounts(discounts) {
            this.discounts = discounts.map(discount => ({
                id: discount.id,
                name: discount.name,
                type: discount.type,
                fixed: discount.fixed,
                percentage: discount.percentage,
                description: discount.description
            }));
        },
        generateBoughtTickets(orders) {
            const boughtTickets = [];
            orders.forEach(order => {
                if (order.order_status === 'succeeded' || (order.order_status === 'new' && order.wait_to_payment)) {
                    const tickets = order.tickets.filter(ticket => {
                        return !ticket.is_cancelled && !ticket.is_refunded
                    }).map(ticket => ({
                        ticketId: ticket.id,
                        categoryId: ticket.event_seat_plan_category_id,
                        categoryName: ticket.category_name,
                        category_key: this.findNameById(this.seatCategories, ticket.event_seat_plan_category_id),
                        row: ticket.row,
                        seat: ticket.seat,
                    }));
                    boughtTickets.push(...tickets);
                }
            });
            return boughtTickets;
        },
        generateSeatCategories(categories) {
            return categories.map((category, categoryIndex) => {
                const aislesAfterArr = category.aisles_after.split(',').map(Number);
                const discountPrices = this.calculateDiscountPrices(category);
                const regularPrice = {
                    name: 'Regular',
                    price: category.price,
                    count: 0,
                }

                return {
                    id: category.id,
                    name: category.name,
                    category_key: category.category_key,
                    price: category.price, // uses by default
                    prices: [regularPrice, ...discountPrices],
                    rows: Array(category.rows)
                        .fill()
                        .map((_, rowIndex) => {
                            return this.generateRow(category.seats, aislesAfterArr, rowIndex + 1, categoryIndex + 1, category.id, category.name)
                        })
                };
            });
        },
        calculateDiscountPrices(category) {
            return this.discounts.map(discount => {
                const price = discount.type === 'percentage'
                    ? category.price - (category.price * (discount.percentage / 100))
                    : category.price - discount.fixed;

                return {
                    discount_id: discount.id,
                    name: discount.name,
                    price: price,
                    count: 0
                };
            });
        },
        selectSeat(categoryIndex, rowIndex, seatIndex) {
            const category = this.seatCategories[categoryIndex]
            let seat = category.rows[rowIndex][seatIndex];
            seat.selected = !seat.selected;
            if (seat.selected) {
                this.cart.push({
                    ...seat,
                    price: this.seatCategories[categoryIndex].price,
                    prices: category.prices
                });
            } else {
                let index = this.cart.findIndex(cartSeat => cartSeat.number === seat.number && cartSeat.rowNumber === seat.rowNumber && cartSeat.categoryIndex === seat.categoryIndex);
                if (index !== -1) {
                    this.cart.splice(index, 1);
                }
            }
        },
        removeSeat(seat) {
            console.log("seat--->", seat)
            let seatNunmber = seat.seatNumber;
            let row = seat.number;
            let category_key = seat.category_key;
            let categoryWithPrefix = `cat-${category_key}`;

            let parentDiv = document.getElementById(categoryWithPrefix)
            console.log("parentDiv-->", parentDiv)

            // Select the <g> element containing id attribute with "row_1"
            let groupElement = parentDiv.querySelector(`g[id*="r${row}_"]`);

            let rectGroupElement = groupElement.querySelector(`rect[id*="s${seatNunmber}_"]`);

            if (rectGroupElement) {
                // Remove the 'selected' class from rectGroupElement
                rectGroupElement.classList.remove('selected');
            }

            let index = this.cart.findIndex(cartSeat => {
                return cartSeat.number === seat.number && cartSeat.seatNumber === seat.seatNumber && cartSeat.categoryIndex === seat.categoryIndex
            });

            if (index !== -1) {
                this.cart.splice(index, 1);
            }
        },
        proceedToCheckout() {
            this.errorMsg = null;
            if (!this.cart.length) {
                this.errorMsg = 'Cart is empty';
                return;
            }

            const tickets = this.filterTickets();
            const total = this.calculateTotal(tickets);

            Cookies.set('cart_tickets', JSON.stringify(tickets));
            Cookies.set('cart_total', total);
            Cookies.set('cart_event', this.eventId);

            this.bookTickets(tickets)
        },
        filterTickets() {
            return this.cart.flatMap(category => {
                return {
                    event_id: this.event.id,
                    count: 1,
                    name: 'Regular',
                    price: category.price,
                    prices: category.prices, // uses when there are some discounts
                    row: category.number,
                    seat: category.seatNumber,
                    total: this.formatPrice(category.price),
                    categoryId: category.categoryId,
                    categoryName: category.categoryName,
                    eventName: this.event.name,
                    eventDate: this.formatDate(this.event.start_date),
                    eventTime: this.event.carbon_start_time,
                    eventLocation: `${this.event.venue.city}, ${this.event.venue.country}`,
                };
            });
        },
        calculateTotal(tickets) {
            const amount = tickets.reduce((total, item) => total + item.price, 0);
            return this.formatPrice(amount)
        },
        async bookTickets(tickets) {
            try {
                const response = await axios.post(`/bookings/events/${this.eventId}`, {
                    event_id: this.eventId,
                    tickets: tickets,
                });

                Cookies.set('cart_bookings', response.data.bookings);
                Cookies.set('cart_session_id', response.data.session_id);

                window.location.href = `/checkout?event_id=${this.eventId}`;

            } catch (error) {
                if (error.response && error.response.data) {
                    this.errorMsg = error.response.data.message;
                    console.error('Booking error:', error.response.data.errors);
                    this.showToastMessage('Booking error:' + error.response.data.errors);
                } else {
                    this.errorMsg = 'An unexpected error occurred. Please try again.';
                    console.error('There was an error processing the checkout:', error);
                    this.showToastMessage('Error booking the tickets. Please try again.');
                }
            }
        },
        formatPrice(price) {
            return new Intl.NumberFormat('us-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(price);
        },
        /*formatDate(date) {
            return date ? new Date(date).toLocaleDateString('de', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }) : '';
        },*/
        formatDate(date) {
            if (!date) return '';
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('de', options);
        },
        formatTime(time) {
            return time ? new Date(time).toLocaleTimeString('de', { hour: 'numeric', minute: 'numeric' }) : '';
        },
        loadDynamicStyles() {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = '/css/svgStyles.css';
            document.head.appendChild(link);
        },
    }
};
</script>

<template>
    <div>
        <!-- Loading Spinner -->
        <div v-if="loading" class="d-flex justify-content-center align-items-center" style="height: 50vh;">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- Main Content -->


        <div :class="{ 'invisible': !visible }">

            <div :class="seatmapClass" id="seatmapDiv">
                <svg
                    class="draggable"
                    :style="{ transform: 'translate(' + dragOffsetX + 'px,' + dragOffsetY + 'px) scale(' + svgScale + ')' }"
                    @mousedown="startDrag"
                    @mousemove="onDrag"
                    @mouseup="endDrag"
                    @mouseleave="endDrag"
                    @touchstart="startDrag"
                    @touchmove="onDrag"
                    @touchend="endDrag"
                    @touchcancel="endDrag"
                    width="100%"
                    height="100%"
                    viewBox="0 0 1000 1000"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    id="svgMapId"

                >
                </svg>
            </div>

            <div class="box-controls">
                    <button class="btn-orange px-3 rounded" @click="hideAndShow" id="slideSelectBox"><i class="fas fa-arrow-right"></i></button>
            </div>
            <div class="selected-box overflow-auto" data-visibility="show">
                <div class="zoom-controls w-100 d-flex justify-content-between">
                    <button class="btn-orange px-3 rounded" @click="zoomIn">Zoom In</button>
                    <button class="btn-orange px-3 rounded" @click="oneToOne">1:1</button>
                    <button class="btn-orange px-3 rounded" @click="zoomOut">Zoom Out</button>
                </div>
                <div class="selected-cats d-md-down">
                    <h2 class="cart-heading">Kategorien</h2>
                    <hr>
                    <div class="d-flex flex-col pt-2 gap-2">
                        <div v-for="(category, categoryIndex) in seatCategories" :key="categoryIndex"
                             class="category d-flex flex-row gap-2 justify-content-between">
                            <div>{{ category.name }}</div>
                            <div>€{{ formatPrice(category.price) }}</div>
                        </div>
                    </div>
                </div>
                <div class="selected-seats">
                    <h2 class="cart-heading">Warenkorb</h2>
                    <hr>
                    <ul class="seat-list pb-2">
                        <li v-for="(seat, index) in selectedSeats" :key="index">
                            <div class="d-flex col px-0 py-2 gap-2 justify-content-between">
                                <div>
                                    <p>1x {{ seat.categoryName }}</p>
                                    <div class="d-flex flex-col px-0">
                                        <small>Reihe: {{ seat.number }}, Platz: {{ seat.seatNumber }}</small>
                                        <small v-if="seat.prices.length === 1"><strong>Preis: {{
                                                formatPrice(seat.price)
                                            }}</strong></small>
                                        <div v-if="seat.prices.length > 1">
                                            <label>Preis: </label>
                                            <select v-model="seat.price" class="form-control">
                                                <option v-for="(price, index) in seat.prices" :key="index"
                                                        :value="price.price">€{{ formatPrice(price.price) }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <button @click="removeSeat(seat)" class="delete-button" style=""></button>
                            </div>
                            <hr>
                        </li>
                    </ul>
                    <div v-if="errorMsg" class="text-danger" style="text-align: center; padding: 10px 0 5px 0;">
                        {{ errorMsg }}
                    </div>
                    <button
                        @click.prevent="proceedToCheckout"
                        :disabled="cart.length === 0"
                        type="button"
                        class="btn btn-orange checkout-btn w-100 mt-2"
                    >
                        <i class="fas fa-money-bill mr-2"></i>Weiter zur Kassa
                    </button>
                </div>
            </div>


        </div>

    </div>
</template>

<style scoped>

</style>
